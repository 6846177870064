import { store } from "./redux/store";
import { currentUploadSelector, pendingUploadsSelector, popPendingUpload, pushFailedUpload, pushPendingUpload, setUploadPendingStorage, setUploadProgress } from "./redux/uploadsSlice";
import Api from "./api/api";
import { clipCreate } from "./redux/clipsSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { taskCreate } from "./redux/tasksSlice";


const maxQueuedUploads = 10;
let listeners = [];

export function queueUpload(upload) {
    const state = store.getState();
    if (numQueuedUploads(state) < maxQueuedUploads) {
        store.dispatch(pushPendingUpload(upload))
        if (!currentUpload(state)) {
            store.dispatch(popPendingUpload())
            startCurrentUpload();
        }
    }
}

export function uploadManagerAddListener(listener) {
    if (!listeners.find(existingListener => existingListener === listener))
        listeners.push(listener);
}

export function uploadManagerRemoveListener(listener) {
    listeners = listeners.filter((existingListener) => existingListener !== listener);
}

function numQueuedUploads(state) {
    return pendingUploadsSelector(state) + (currentUploadSelector(state) ? 1 : 0)
}

function currentUpload(state) {
    return currentUploadSelector(state);
}

function startCurrentUpload() {
    const state = store.getState();
    const upload = currentUpload(state);
    if (!upload)
        return;
    postUpload(upload);
}

function onUploadProgress(upload, progress) {
    store.dispatch(setUploadProgress(progress));
}

function onUploadSucceeded(upload) {
    listeners.forEach(listener => listener({ result: "upload_succeeded", filename: upload.filename }))
    store.dispatch(popPendingUpload());
    startCurrentUpload();
}

function onUploadAborted(upload) {
    listeners.forEach(listener => listener({ result: "upload_aborted", filename: upload.filename }))
    store.dispatch(popPendingUpload());
    startCurrentUpload();
}

function onUploadFailed(upload, reason) {
    listeners.forEach(listener => listener({ result: "upload_failed", filename: upload.filename, errorMessage: reason }))
    store.dispatch(popPendingUpload());
    startCurrentUpload();
    store.dispatch(pushFailedUpload({
        ...upload,
        errorMessage: reason,
    }))
}

async function postUpload(upload) {
    const { url } = await Api.createUpload({ filename: upload.filename, sizeBytes: upload.pendingStorage });
    let request = new XMLHttpRequest();
    request.open('PUT', url);
    request.setRequestHeader('Content-Type', 'audio/wav');
    // request.setRequestHeader('Content-Length', sizeBytes);
    request.setRequestHeader('x-amz-tagging', 'autoremove=1');
    request.upload.addEventListener('progress', function (e) {
        let percentCompleted = (e.loaded / e.total) * 100;
        store.dispatch(setUploadProgress(percentCompleted));
        onUploadProgress(upload, percentCompleted);
    });
    request.addEventListener('load', async function (e) {
        if (request.status === 200) {
            try {
                // TODO: how to refrain from updating storage once clip has been uploaded
                // it temporarily jumps to higher value until onUploadSucceeded is called
                // TODO: store.dispatch method may throw, how to intercept?
                const clipCreateResult = await store.dispatch(clipCreate(url));
                store.dispatch(setUploadPendingStorage(0));
                let { clip } = unwrapResult(clipCreateResult);
                await store.dispatch(taskCreate({ 
                    clipId: clip.id, 
                    steps: upload.steps
                }));
                onUploadSucceeded(upload);
                // TODO: add task entity, add to collections, too (or invalidate collections by making undefined)
                // store.dispatch(setQuota(quotaAfterTask));
        } catch (err) {
            onUploadFailed(upload, err.message);
        }
    } else {
        onUploadFailed(upload, `http status code ${request.status}`);
        }
    });
    request.addEventListener('error', function (e) {
        onUploadFailed(upload, "Upload failed");
    });
    request.addEventListener('abort', function (e) {
        onUploadAborted(upload, "Upload aborted");
    });
    let response = await fetch(upload.localUrl);
    let body = await response.blob();
    request.send(body);
}
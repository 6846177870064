import { useCallback, useEffect, useRef } from "react";
import { MoonLoader } from "react-spinners";

export default function LoadingCard({ currentRequestId, fetchMore }) {
    const ref = useRef(null);

    const intersectionCallback = useCallback((e) => {
        if (currentRequestId === undefined && e[0].isIntersecting) {
            fetchMore?.()
        }
    }, [currentRequestId, fetchMore])

    useEffect(() => {
        if (fetchMore !== undefined) {
            const observer = new IntersectionObserver(intersectionCallback, {
                root: null,
                rootMargin: "0px",
                threshold: "0.0"
            })

            const element = ref.current;

            if (ref.current)
                observer.observe(ref.current);

            return () => {
                if (element)
                    observer.unobserve(element);
            }
        }
    }, [intersectionCallback, fetchMore])

    return (
        <div ref={ref} style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <MoonLoader size="30px"/>
        </div>
    )
}
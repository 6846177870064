import { useParams } from "react-router-dom";
import { cdnUrl } from "../../App";
import mic_png from '../../assets/mic.png';
import mic_icon_png from "../../assets/mic_icon.png";
import { secondsToHhMmSs } from "../../utils";
import VoiceLikes from "../widgets/VoiceLikes";
import { ModelCard } from "../cards/ModelCard";
import { useSelector } from "react-redux";
import { modelsByVoiceId } from "../../redux/modelsSlice";
import { ScaleLoader } from "react-spinners";
import { useCollection } from "../../redux/collection";
import { useEntity } from "../../redux/entity";
import { voiceEntity } from "../../redux/voicesSlice";
import { HotText } from "../widgets/HotText";
import { meSelector } from "../../redux/globalSlice";


export default function Voice() {
    let { voiceId } = useParams();
    const { data: voice } = useEntity(voiceEntity(voiceId)) || {};

    if (voice) {
        return (
            <div style={{ position: "relative", paddingTop: "40pt", display: "flex", flexDirection: "column", margin: "auto", gap: "12pt" }}>
                <VoiceInfo voice={voice} />
                <h3 style={{ margin: "0", fontSize: "16pt", textAlign: "center" }}>MODELS:</h3>
                <ModelList voiceId={voiceId} />
            </div>
        )
    } else {
        return (
            <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", position: "relative", width: '100%', height: '100%' }}>
                <ScaleLoader color="var(--nfai-black)" width="50pt" height="50pt" loading="true" style={{ loading: true, opacity: "0.5" }} />
            </div>
        )
    }
}

function VoiceInfo({ voice }) {
    const imageSrc = voice.have_image ? `${cdnUrl}/public/${voice.owner_id}/voices/${voice.id}/image.png` : mic_png;

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-start', width: "100%", gap: '20pt' }}>
            <div style={{ position: "relative", aspectRatio: "1.0", width: "30%" }} >
                <img src={imageSrc} alt="VOICE IMG" style={{ objectFit: "cover", position: "absolute", aspectRatio: "1", width: "100%", borderRadius: "5pt", borderColor: "var(--nfai-black)", borderWidth: "1pt", borderStyle: "solid" }} />
            </div>
            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", flexFlow: "column", width: "70%" }}>
                <h1 style={{ margin: "0 0 10pt 0" }}>{voice.name}</h1>
                <p style={{ margin: "0", textAlign: "justify", maxHeight: "48%", overflow: "scroll" }}>{voice.description}</p>
                <div style={{ marginTop: "15pt", display: "flex", justifyContent: "flex-start", alignItems: "center", width: "100%", gap: "4pt" }}>

                    <div style={{ display: "flex", width: "20pt", height: "20pt" }} >
                        <img src={mic_icon_png} alt="MIC" style={{ objectFit: "contain", width: "20pt", height: "20pt" }} />
                    </div>
                    {
                        (voice.num_completed_tasks && voice.studio_time) ?
                            (<>
                                appeared on {voice.num_completed_tasks} {voice.num_completed_tasks > 1 ? "sessions" : "session"}, total time {secondsToHhMmSs(voice.studio_time)}
                            </>) :
                            (<>
                                no sessions yet - be first!
                            </>)
                    }
                </div>
                <div style={{ marginTop: "15pt", display: "flex", justifyContent: "flex-start", alignItems: "center", width: "100%" }}>
                    <div style={{ display: 'flex', width: '100%', height: '20pt', fontSize: "13pt", gap: "6pt" }}>
                        <VoiceLikesVerbose voice={voice} />
                    </div>
                </div>
            </div>
        </div >
    )
}

function ModelList({ voiceId }) {
    const { ids: modelIds } = useCollection(modelsByVoiceId(voiceId)) || {}

    return (
        <>
            {
                modelIds?.map((modelId) => {
                    return (<ModelCard key={modelId} modelId={modelId} />)
                })
            }
        </>
    );
}

function VoiceLikesVerbose({ voice }) {
    const me = useSelector(meSelector);

    if (!voice)
        return null;
    
    let likers = voice.liked_by_me ? [me.id] : [];

    if (voice.some_likers)
        likers = [...likers, ...voice.some_likers];
    let likesText = "liked by " + likers.map((likerId => `___USER_${likerId}___`)).join(", ");
    if (voice.num_likes > likers.length)
        likesText += ` and ${voice.num_likes - likers.length} more`

    return (
        <>
            <div style={{ display: "flex", objectFit: "cover", width: "20pt", height: "20pt" }} >
                <VoiceLikes voiceId={voice.id} />
            </div>
            {likers.length > 0 ?
                (<p style={{ margin: "0" }}><HotText text={likesText} /></p>) :
                (<p style={{ margin: "0" }}>no likes yet</p>)
            }
        </>
    )

}
import stop_png from "../../assets/stop.png"
import warning_png from "../../assets/warning.png"
import { useEffect, useRef, useState } from "react";

export const AlertType = {
    Info: 0,
    Warning: 1,
    Error: 2,
}

export function Alert({ type, header, isOpen, hasCloseBtn, onClose, children }) {

    const [isModalOpen, setModalOpen] = useState(isOpen);

    const modalRef = useRef(null);

    useEffect(() => {
        setModalOpen(isOpen);
    }, [isOpen]);

    useEffect(() => {
        const modalElement = modalRef.current;
        if (modalElement) {
            if (isModalOpen) {
                modalElement.showModal();
            } else {
                modalElement.close();
            }
        }
    }, [isModalOpen]);

    const handleCloseModal = () => {
        if (onClose) {
            onClose();
        }
        setModalOpen(false);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Escape") {
            handleCloseModal();
        }
    };

    let imgSource;
    if (type === AlertType.Warning)
        imgSource = warning_png;
    else if (type === AlertType.Error)
        imgSource = stop_png;

    return (
        <dialog ref={modalRef} onKeyDown={handleKeyDown} >
            <div style={{ width: "300pt", height: "200pt" }}>
                <div style={{ backgroundColor: "var(--nfai-black)", position: "absolute", top: "0pt", width: "100%", height: "30pt" }}>
                    {
                        imgSource &&
                        <div style={{ position: "absolute", left: "5pt", top: "5pt", width: "20pt", height: "20pt" }}>
                            <img src={imgSource} alt="ICON" style={{ objectFit: "contain", width: "100%", height: "100%" }} />
                        </div>
                    }
                    <p style={{ color: "var(--nfai-washed-white)", textAlign: "center", margin: "0", position: "absolute", top: "5pt", width: "100%", height: "20pt" }}>
                        {header}
                    </p>
                    {/* {
                        hasCloseBtn &&
                        <div style={{ position: "absolute", right: "5pt", top: "5pt", width: "20pt", height: "20pt" }}>
                            <img src={cross_png} alt="ICON" style={{ objectFit: "contain", width: "100%", height: "100%" }} />
                        </div>
                    } */}
                </div>
                <div style={{ display: "flex", flexFlow: "column", justifyContent: "center", alignItems: "center", gap: "20pt", position: "absolute", top: "36pt", bottom: "0pt", width: "100%", padding: "12pt"}}>
                    {children}
                    { hasCloseBtn && <button onClick={handleCloseModal} className="button active" >CLOSE</button>}
                </div>
            </div>
        </dialog>
    )
}
import { Alert, AlertType } from "./Alert";
import { secondsToHhMmSs } from "../../utils";


export default function InsufficientBalanceOrStorageModal({ isOpen, onClose, requiredBalance, availableBalance, requiredStorage, availableStorage }) {

    let header;
    if (requiredBalance > availableBalance && requiredStorage > availableStorage)
       header = "INSUFFICIENT TIME AND STORAGE";
    else if (requiredBalance > availableBalance)
        header = "INSUFFICIENT TIME";
    else if (requiredStorage > availableStorage)
        header = "INSUFFICIENT STORAGE";


    return (
        <Alert
            type={AlertType.Warning}
            hasCloseButton={true}
            isOpen={isOpen}
            onClose={onClose}
            hasCloseBtn={true}
            header={header}
        >
            {
                requiredBalance > availableBalance &&
                <p style={{ margin:"0", textAlign: "center" }}>The audio duration is {secondsToHhMmSs(requiredBalance / 100, true)}. This is longer than your available processing time of {secondsToHhMmSs(availableBalance, true)}</p>
            }
            {
                requiredStorage > availableStorage &&
                <p style={{ margin: "0", textAlign: "center" }}>The required storage is {Math.round(100 * requiredStorage / (1024 * 1024)) / 100} MB. You only have {Math.round(100 * availableStorage / (1024 * 1024)) / 100} MB available.</p>
            }
        </Alert>
    );
}
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";


export function addEntityCreateReducers(builder, thunk, entitySelector, intercept) {
    builder.addCase(thunk.pending, (state, action) => {
        // we don't even know the ID yet; not a big deal, POST is not expected to be idempotent anyway
    })
    builder.addCase(thunk.fulfilled, (state, action) => {
        const entityData = entitySelector(action.payload);
        const id = entityData.id;
        if (state.entities[id])     // already there!
            return;
        state.entities[id] = {
            data: entityData,
            currentRequestId: undefined,
            lastFetched: Date.now(),
        }
        intercept?.(state, entityData)
    })
    builder.addCase(thunk.rejected, (state, action) => {
    })
}

export function addEntityFetchReducers(builder, thunk) {
    builder.addCase(thunk.pending, (state, action) => {
        const id = action.meta.arg;
        let entity = state.entities[id];
        if (entity) {
            if (entity.currentRequestId)        // ongoing request
                return;
            entity.currentRequestId = action.meta.requestId;
        } else {
            state.entities[id] = {
                data: undefined,
                lastFetched: undefined,
                currentRequestId: action.meta.requestId,
                error: undefined,
            }
        }
    })
    builder.addCase(thunk.fulfilled, (state, action) => {
        const id = action.meta.arg;
        const entity = state.entities[id];
        if (entity.currentRequestId !== action.meta.requestId)
            return;
        entity.currentRequestId = undefined;
        entity.lastFetched = Date.now();
        entity.data = action.payload;
    })
    builder.addCase(thunk.rejected, (state, action) => {
        const id = action.meta.arg;
        const entity = state.entities[id];
        if (entity.currentRequestId !== action.meta.requestId)
            return;
        entity.currentRequestId = undefined;
        entity.error = action.error;
    })
}

export function addEntityUpdateReducers(builder, thunk) {
    builder.addCase(thunk.pending, (state, action) => {
        const { id, updates } = action.meta.arg;
        let entity = state.entities[id];
        if (!entity || entity.currentRequestId)     // not there or ongoing request
            return;
        entity.currentRequestId = action.meta.requestId;
        entity.oldData = { ...entity.data };
        Object.entries(updates).forEach(([key, value]) => {
            entity.data[key] = value;
        })
    })
    builder.addCase(thunk.fulfilled, (state, action) => {
        const { id } = action.meta.arg;
        const entity = state.entities[id];
        if (entity.currentRequestId !== action.meta.requestId)
            return;
        entity.currentRequestId = undefined;
        entity.lastFetched = Date.now();
        entity.data = action.payload;
        entity.oldData = undefined;
    })
    builder.addCase(thunk.rejected, (state, action) => {
        const { id } = action.meta.arg;
        const entity = state.entities[id];
        if (entity.currentRequestId !== action.meta.requestId)
            return;
        entity.currentRequestId = undefined;
        entity.data = { ...entity.oldData };
        entity.oldData = undefined;
        entity.error = action.error;
    })
}

// extraReducer is called on fulfilled and allows for extra state operations
// such as removing the entity IDs from collections
export function addEntityDeleteReducers(builder, thunk, intercept) {
    builder.addCase(thunk.pending, (state, action) => {
        const id = action.meta.arg;
        let entity = state.entities[id];
        if (!entity || entity.currentRequestId)
            return;
        entity.currentRequestId = action.meta.requestId;
    })
    builder.addCase(thunk.fulfilled, (state, action) => {
        const id = action.meta.arg;
        const entity = state.entities[id];
        if (entity.currentRequestId !== action.meta.requestId)
            return;
        delete state.entities[id];
        intercept?.(state, id)
    })
    builder.addCase(thunk.rejected, (state, action) => {
        const id = action.meta.arg;
        const entity = state.entities[id];
        if (entity.currentRequestId !== action.meta.requestId)
            return;
        entity.currentRequestId = undefined;
        entity.error = action.error;
    })
}

export function useRealtimeEntity({ selector, action }, pollInterval = 10 * 1000) {
    const dispatch = useDispatch();
    const entity = useSelector(selector);
    const [suspended, setSuspended] = useState(false);
    
    useEffect(() => {
        if (!suspended) {
            const interval = setInterval(() => {
                if (!suspended)
                    dispatch(action);
            }, pollInterval);
            return () => {
                clearInterval(interval);
            }
        }
    }, [dispatch, action, pollInterval, suspended]);

    return { entity, setSuspended };
}

export function useEntity({ selector, action }, cacheExpiry = 60 * 1000) {
    const dispatch = useDispatch();
    const entity = useSelector(selector);
    const lastFetched = entity?.lastFetched;
    const [needRefresh, setNeedRefresh] = useState(!lastFetched || Date.now() - lastFetched > cacheExpiry);

    useEffect(() => {
        if (needRefresh) {
            dispatch(action)
            setNeedRefresh(false);
        }
    }, [needRefresh, selector, action, dispatch]);

    return entity;
}

import { Auth } from "aws-amplify";

const apiUrl = process.env.REACT_APP_API_URL;

// const apiUrl = "http://localhost:8000"


export default class Api {

  static async #getHeaders() {
    const session = await Auth.currentSession();
    const jwt = session.getIdToken().getJwtToken();
    return {
      'Accept': 'application/json',
      'Authorization': jwt,
    };
  }

  static async getMe() {
    const response = await fetch(apiUrl + "/me", {
      method: 'GET',
      headers: await this.#getHeaders(),
      cache: 'default',
    });
    if (response.status === 200) {
      return await response.json();
    } else {
      throw Error("HTTP code: " + response.status);
    }
  }

  static async getUser(userId) {
    const response = await fetch(`${apiUrl}/users/${userId}`, {
      method: 'GET',
      headers: await this.#getHeaders(),
      cache: 'default',
    });
    if (response.status === 200) {
      return await response.json();
    } else {
      throw Error("HTTP code: " + response.status);
    }
  }

  static async getVoices({ sources, grantorId, link, limit, desc }) {
    let url = apiUrl + "/voices";
    let conditions = [];
    if (sources)
      conditions.push(`sources=${sources.join(",")}`);
    // TODO: add favorite handling to the endpoint
    // if (favoriteOnly)
    //   conditions.push("fav=1");
    if (grantorId)
      conditions.push(`grantorId=${grantorId}`);
    if (link)
      conditions.push(`link=${link}`);
    if (limit)
      conditions.push(`limit=${limit}`);
    if (desc)
      conditions.push("desc=1");
    if (conditions.length > 0)
      url += `?${conditions.join("&")}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: await this.#getHeaders(),
      cache: 'default',
    });
    if (response.status === 200) {
      return await response.json();
    } else {
      throw Error("HTTP status: " + response.status);
    }
  }

  static async getVoice(voiceId) {
    let url = `${apiUrl}/voices/${voiceId}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: await this.#getHeaders(),
      cache: 'default',
    });
    if (response.status === 200) {
      return await response.json();
    } else {
      throw Error("HTTP status: " + response.status);
    }
  }

  static async patchVoice(voiceId, updates) {
    let url = `${apiUrl}/voices/${voiceId}`;
    const response = await fetch(url, {
      method: 'PATCH',
      headers: await this.#getHeaders(),
      body: JSON.stringify(updates),
    });
    if (response.status === 200) {
      return await response.json();
    } else {
      throw Error("Voice update request failed. Status " + response.status);
    }
  }

  static async getModels({ voiceId, offset, limit, sort, desc }) {
    let url = `${apiUrl}/voices/${voiceId}/models`;
    let conditions = [];
    if (offset)
      conditions.push(`offset=${offset}`);
    if (limit)
      conditions.push(`limit=${limit}`);
    if (sort) {
      conditions.push(`sort=${sort}`);
      if (desc)
        conditions.push("desc=1");
    }
    if (conditions.length > 0)
      url += `?${conditions.join("&")}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: await this.#getHeaders(),
      cache: 'default',
    });
    if (response.status === 200) {
      return await response.json();
    } else {
      throw Error("HTTP status: " + response.status);
    }
  }

  static async getModel(modelId) {
    const url = `${apiUrl}/models/${modelId}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: await this.#getHeaders(),
      cache: 'default',
    });
    if (response.status === 200) {
      return await response.json();
    } else {
      throw Error("HTTP status: " + response.status);
    }
  }

  static async createUpload({ filename, sizeBytes }) {
    const url = `${apiUrl}/uploads`;
    const response = await fetch(url, {
      method: "POST",
      headers: await this.#getHeaders(),
      body: JSON.stringify({
        "filename": filename,
        "sizeBytes": sizeBytes,
      })
    });
    if (response.status === 201) {
      return await response.json();
    } else {
      throw Error("HTTP status: " + response.status);
    }
  }

  static async createClip({ fileUrl }) {
    const url = `${apiUrl}/clips`;
    const response = await fetch(url, {
      method: "POST",
      headers: await this.#getHeaders(),
      body: JSON.stringify({
        "url": fileUrl,
      })
    });
    if (response.status === 201) {
      return await response.json();
    } else {
      throw Error("HTTP status: " + response.status);
    }
  }

  static async getClips({ extended, offset, limit, sort, desc }) {
    let conditions = [];
    if (extended)
      conditions.push('extended=1')
    if (offset)
      conditions.push(`offset=${offset}`);
    if (limit)
      conditions.push(`limit=${limit}`);
    if (sort) {
      conditions.push(`sort=${sort}`);
      if (desc)
        conditions.push("desc=1");
    }
    const url = `${apiUrl}/clips${conditions.length > 0 ? '?' + conditions.join('&') : ''}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: await this.#getHeaders(),
      cache: 'default',
    });
    if (response.status === 200) {
      return await response.json();
    } else {
      throw Error("HTTP code: " + response.status);
    }
  }

  static async getClip(clipId) {
    const url = `${apiUrl}/clips/${clipId}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: await this.#getHeaders(),
      cache: 'default',
    });
    if (response.status === 200) {
      return await response.json();
    } else {
      throw Error("HTTP status: " + response.status);
    }
  }

  static async deleteClip(clipId) {
    const url = `${apiUrl}/clips/${clipId}`;
    const response = await fetch(url, {
      method: 'DELETE',
      headers: await this.#getHeaders(),
      cache: 'default',
    });
    if (response.status !== 200)
      throw Error("HTTP status: " + response.status);
    return await response.json();
  }

  static async createTask({ clipId, steps }) {
    const url = `${apiUrl}/clips/${clipId}/tasks`;
    const response = await fetch(url, {
      method: "POST",
      headers: await this.#getHeaders(),
      body: JSON.stringify(steps)
    });
    if (response.status === 201) {
      return await response.json();
    } else {
      throw Error("HTTP status: " + response.status);
    }
  }

  static async getTasks({ clipId, statuses, link, limit, desc }) {
    let conditions = []
    if (clipId)
      conditions.push(`clipId=${clipId}`)
    if (statuses && statuses.length > 0)
      conditions.push(`status=${statuses.join(',')}`)
    if (link)
      conditions.push(`link=${link}`);
    if (limit)
      conditions.push(`limit=${limit}`);
    if (desc)
      conditions.push("desc=1");
    const url = `${apiUrl}/tasks${conditions.length > 0 ? '?' + conditions.join('&') : ''}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: await this.#getHeaders(),
      cache: 'default',
    });
    if (response.status === 200) {
      return await response.json();
    } else {
      throw Error("HTTP code: " + response.status);
    }
  }

  static async getTask(taskId) {
    const url = `${apiUrl}/tasks/${taskId}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: await this.#getHeaders(),
      cache: 'default',
    });
    if (response.status === 200) {
      return await response.json();
    } else {
      throw Error("HTTP status: " + response.status);
    }
  }

  static async deleteTask(taskId) {
    const url = `${apiUrl}/tasks/${taskId}`;
    const response = await fetch(url, {
      method: 'DELETE',
      headers: await this.#getHeaders(),
      cache: 'default',
    });
    if (response.status !== 200)
      throw Error("HTTP status: " + response.status);
    return await response.json();
  }

  static async createProductCheckout(productId) {
    const url = `${apiUrl}/products/${productId}/checkouts`;
    const response = await fetch(url, {
      method: 'POST',
      headers: await this.#getHeaders()
    });
    if (response.status === 201) {
      return await response.json();
    } else {
      throw Error("HTTP code: " + response.status);
    }
  }

  static async createPlanCheckout(planId) {
    const url = `${apiUrl}/plans/${planId}/checkouts`
    const response = await fetch(url, {
      method: 'POST',
      headers: await this.#getHeaders()
    });
    if (response.status === 201) {
      return await response.json();
    } else {
      throw Error("HTTP code: " + response.status);
    }
  }
  
  static async getSubscription() {
    const url = `${apiUrl}/me/subscription`
    const response = await fetch(url, {
      method: 'GET',
      headers: await this.#getHeaders()
    });
    if (response.status === 200) {
      return await response.json();
    } else {
      throw Error("HTTP code: " + response.status);
    }
  }
    
  static async cancelSubscription() {
    const url = `${apiUrl}/me/subscription`
    const response = await fetch(url, {
      method: 'DELETE',
      headers: await this.#getHeaders()
    });
    if (response.status === 200) {
      return await response.json();
    } else {
      throw Error("HTTP code: " + response.status);
    }
  }

  static async resumeSubscription() {
    const url = `${apiUrl}/me/subscription`
    const response = await fetch(url, {
      method: 'POST',
      headers: await this.#getHeaders()
    });
    if (response.status === 200) {
      return await response.json();
    } else {
      throw Error("HTTP code: " + response.status);
    }
  }

  static async getProducts() {
    const url = `${apiUrl}/products`;
    const response = await fetch(url, {
      method: 'GET',
      headers: await this.#getHeaders(),
      cache: 'default',
    });
    if (response.status === 200) {
      return await response.json();
    } else {
      throw Error("HTTP code: " + response.status);
    }
  }

  static async getProduct(productId) {
    const url = `${apiUrl}/products/${productId}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: await this.#getHeaders(),
      cache: 'default',
    });
    if (response.status === 200) {
      return await response.json();
    } else {
      throw Error("HTTP status: " + response.status);
    }
  }

  static async getPlans() {
    const url = `${apiUrl}/plans`;
    const response = await fetch(url, {
      method: 'GET',
      headers: await this.#getHeaders(),
      cache: 'default',
    });
    if (response.status === 200) {
      return await response.json();
    } else {
      throw Error("HTTP code: " + response.status);
    }
  }

  static async getPlan(planId) {
    const url = `${apiUrl}/plans/${planId}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: await this.#getHeaders(),
      cache: 'default',
    });
    if (response.status === 200) {
      return await response.json();
    } else {
      throw Error("HTTP status: " + response.status);
    }
  }

}
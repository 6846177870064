import { useEntity, useRealtimeEntity } from "../../redux/entity";
import { TaskStatus, deleteTask, taskEntity } from "../../redux/tasksSlice";
import AudioClip from "../widgets/AudioClip";
import { cdnUrl } from "../../App";
import { clipEntity, deleteClip } from "../../redux/clipsSlice";
import { modelEntity } from "../../redux/modelsSlice";
import { voiceEntity } from "../../redux/voicesSlice";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import trash_png from "../../assets/trash.png";
import fast_forward_png from "../../assets/fast_forward.png";
import { useDispatch } from "react-redux";
import { MoonLoader } from "react-spinners";
import LoadingCard from "../widgets/LoadingCard";


export default function TaskCard({ taskId, parentRef }) {
    const { entity: { data: task, currentRequestId }, setSuspended } = useRealtimeEntity(taskEntity(taskId)) || {};
    
    const ref = useRef(null);

    const intersectionCallback = useCallback((e) => {
        const suspend = currentRequestId !== undefined || !e[0].isIntersecting || task.status === TaskStatus.Succeeded;
        // ref.current.style.opacity = suspend ? "0.5" : "1.0";
        setSuspended(suspend);
    }, [currentRequestId, task, setSuspended])

    useEffect(() => {
        const observer = new IntersectionObserver(intersectionCallback, {
            root: parentRef.current,
            rootMargin: "0px",
            threshold: "0.0"
        })

        const currentRef = ref.current;

        if (currentRef)
            observer.observe(currentRef);

        return () => {
            if (currentRef)
                observer.unobserve(currentRef);
        }
    }, [intersectionCallback, parentRef])

    if (!task) {
        return <div style={{
            backgroundColor: "var(--nfai-darkcyan)", position: 'relative', width: '100%', height: '60pt', padding: '0pt 5pt 0pt 5pt', borderRadius: "3pt", borderStyle: "solid", borderColor: "var(--nfai-black)", borderWidth: "1pt"
        }}>
            <LoadingCard />
        </div>
    } else {
        return (
            <div ref={ref}>
                <TaskCardContent task={task} currentRequestId={currentRequestId} />
            </div>
        )
    }
}

function TaskCardContent({ task }) {
    const dispatch = useDispatch();
    const { data: clip/*, currentRequestId: pendingClipRequest*/ } = useEntity(clipEntity(task.clip_id)) || {}
    // const { data: model/*, currentRequestId: pendingModelRequest*/ } = useEntity(modelEntity(task.model_id)) || {}

    const [deleting, setDeleting] = useState(false);

    const onDownload = useCallback((url, filename) => {
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        link.click();
    }, []);

    const onDelete = useCallback(async () => {
        if (!clip || task.status === TaskStatus.Queued || task.status === TaskStatus.Processing)
            return;
        setDeleting(true);
        try {
            await dispatch(deleteTask(task.id)).unwrap();
            await dispatch(deleteClip(clip.id)).unwrap();
        } catch (err) {
            console.log(err)
        } finally {
            setDeleting(false)
        }
    }, [task, clip, dispatch]);

    if (!clip/* || !model*/) {
        return <div style={{
            backgroundColor: "var(--nfai-darkcyan)", position: 'relative', width: '100%', height: '60pt', padding: '0pt 5pt 0pt 5pt', borderRadius: "3pt", borderStyle: "solid", borderColor: "var(--nfai-black)", borderWidth: "1pt"
        }}>
            <LoadingCard />
        </div>

    }

    const canBeDeleted = task.status !== TaskStatus.Queued && task.status !== TaskStatus.Processing;

    return (
        <div style={{
            backgroundColor: "var(--nfai-darkcyan)", position: 'relative', width: '100%', height: '60pt', padding: '0pt 5pt 0pt 5pt', borderRadius: "3pt", borderStyle: "solid", borderColor: "var(--nfai-black)", borderWidth: "1pt"
        }}>
            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'flex-start', alignItems: 'center', width: "100%", height: '100%', gap: "5pt" }}>

                <div style={{ flexGrow: "1", flexShrink: "2", height: "50pt" }}>
                    <div style={{
                        margin: "auto auto auto 0", position: "relative", display: "flex", justifyContent: "center", justifyItems: "center", alignItems: "center", width: "100%", height: "100%", backgroundColor: "var(--nfai-yellow)", borderStyle: "solid", borderColor: "var(--nfai-black)", borderRadius: "3pt", borderWidth: "1pt"
                    }}>
                        <AudioClip
                            url={clip.url}
                            name={clip.filename}
                            onDownload={() => onDownload(clip.url, clip.filename)}
                        />
                    </div>
                </div>

                <div style={{ display: "flex", flexGrow: "0", aspect: "1", height: "24pt" }}>
                    <img src={fast_forward_png} alt="ARR" />
                </div>

                {/* <div style={{ flexGrow: "0", display: "flex", flexFlow: "column", height: "50pt" }}>
                    <div style={{ overflow: "hidden", display: "flex", height: "50pt", aspectRatio: "1", borderRadius: "3pt", borderStyle: "solid", borderWidth: "1pt" }}>
                        <ModelThumbnail model={model} />
                    </div>
                </div> */}

                <div style={{ flexGrow: "1", flexShrink: "1" }}>
                    <div style={{ height: "50pt" }}>
                        <TaskResult task={task} clip={clip} />
                    </div>
                </div>

                <div style={{ display: "flex", flexGrow: "0", aspectRatio: "1", height: "44pt" }}>
                    {
                        deleting ?
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}><MoonLoader size={30} /></div> :
                            <img
                                onClick={onDelete}
                                src={trash_png}
                                alt="DEL"
                                style={{
                                    margin: "12pt",
                                    opacity: canBeDeleted ? "1.0" : "0.25",
                                    cursor: canBeDeleted ? "pointer" : "default"
                                }}
                            />
                    }
                </div>
            </div>
        </div >
    );
}

function ModelThumbnail({ model }) {
    const navigate = useNavigate();
    const { data: voice } = useEntity(voiceEntity(model.voice_id)) || {};
    if (!voice)
        return null;

    const imageUrl = `${cdnUrl}/public/${voice.owner_id}/voices/${voice.id}/image.png`;
    // const audioUrl = `${cdnUrl}/public/${voice.owner_id}/voices/${voice.id}/models/${model.id}/sample.wav`;

    return (
        <div onClick={() => navigate(`/voices/${model.voice_id}`)} style={{ cursor:"pointer", position: "relative", width: "100%", height: "100%" }}>
            <img src={imageUrl} alt="VOICE" style={{ position: "absolute", width: "100%", height: "100%" }} />
            {/* <AudioPlayButton url={audioUrl} /> */}
        </div>
    );
}

function TaskResult({ task, clip }) {

    const onDownload = useCallback((url, filename) => {
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        link.click();
    }, []);

    if (task.status === TaskStatus.Pending || task.status === TaskStatus.Queued || task.status === TaskStatus.Processing) {
        const statusText = task.status === TaskStatus.Processing ? `processing - ${Math.round(task.progress)}%` : task.status;
        return (
            <div style={{
                background: "radial-gradient(circle, rgba(207,151,175,1) 0%, rgba(119,151,189,1) 100%)",
                backgroundColor: "var(--nfai-dark-cyan)", margin: "auto auto auto 0", position: "relative", display: "flex", justifyContent: "center", justifyItems: "center", alignItems: "center", width: "100%", height: "100%", borderStyle: "solid", borderColor: "var(--nfai-black)", borderRadius: "3pt", borderWidth: "1pt"
            }}>
                <p style={{ margin: "0", position: "absolute", fontSize: "10pt", textAlign: "center", fontWeight: "bold", pointerEvents: "none" }}>
                    {statusText}
                </p>
            </div>
        )
    } else if (task.status === TaskStatus.Succeeded) {
        return (
            <div style={{
                background: "radial-gradient(circle, rgba(207,151,175,1) 0%, rgba(119,151,189,1) 100%)",
                backgroundColor: "var(--nfai-dark-cyan)", margin: "auto auto auto 0", position: "relative", display: "flex", justifyContent: "center", justifyItems: "center", alignItems: "center", width: "100%", height: "100%", borderStyle: "solid", borderColor: "var(--nfai-black)", borderRadius: "3pt", borderWidth: "1pt"
            }}>
                <AudioClip
                    url={task.url}
                    name={clip.filename}
                    onDownload={() => onDownload(task.url, clip.filename)} />
            </div>
        )
    } else if (task.status === TaskStatus.Failed) {
        return <></>
    }
}

// normalized, as advised in https://redux.js.org/usage/structuring-reducers/normalizing-state-shape

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../api/api";
import { logIn, logOut } from "./globalSlice";
import { addEntityFetchReducers } from "./entity";


export const usersSlice = createSlice({
    name: 'users',
    initialState: {
        entities: {},
        collections: {}
    },
    extraReducers(builder) {
        addEntityFetchReducers(builder, fetchUserThunk)
        builder.addCase(logIn, (state, action) => {
            const user = action.payload.data;
            state.entities[user.id] = user;
        })
        builder.addCase(logOut, (state, action) => {
            state.entities = {};
            state.collections = {};
        })
    }
})

const fetchUserThunk = createAsyncThunk('users/fetchUser', async (userId, { getState }) => {
    return await Api.getUser(userId);
})
export const userEntity = (userId) => {
    return {
        selector: (state) => state.users.entities[userId],
        action: fetchUserThunk(userId)
    }
}

export default usersSlice.reducer
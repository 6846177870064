export function secondsToHhMmSs(seconds, trimZeros = true) {
    const date = new Date(null);
    const secondsAbs = Math.abs(seconds);
    const prefix = seconds < 0 ? "-" : "";
    date.setSeconds(secondsAbs); // specify value for SECONDS here
    if (secondsAbs >= 3600 || !trimZeros)
        return prefix + date.toISOString().slice(11, 19);
    else
        return prefix + date.toISOString().slice(14, 19);
}

export function bytesToString(bytes) {
    if (bytes >= 1024 * 1024 * 1024 * 1024)                // TB
        return `${Math.round(100 * bytes / (1024 * 1024 * 1024 * 1024)) / 100} TB`;
    if (bytes >= 1024 * 1024 * 1024)                // GB
        return `${Math.round(100 * bytes / (1024 * 1024 * 1024)) / 100} GB`;
    if (bytes >= 1024 * 1024)                // MB
        return `${Math.round(100 * bytes / (1024 * 1024)) / 100} MB`;
    if (bytes >= 1024)                // KB
        return `${Math.round(100 * bytes / 1024) / 100} KB`;
    else
        return `bytes B`;
}

import { useEffect, useState } from "react";
import { cdnUrl } from "../../App";
import FileDropArea from "../widgets/FileDropArea";
import AudioClip from "../widgets/AudioClip";
import { modelEntity } from "../../redux/modelsSlice";
import { useEntity } from "../../redux/entity";
import { voiceEntity } from "../../redux/voicesSlice";
import { queueUpload } from "../../uploadManager";
import InsufficientBalanceOrStorageModal from "../modal/InsufficientBalanceOrStorageModal";
import { useSelector } from "react-redux";
import { uploadsPendingCostSelector, uploadsPendingStorageSelector } from "../../redux/uploadsSlice";
import { creditSelector, storageSelector, subscriptionSelector } from "../../redux/globalSlice";

export function ModelCard({ modelId }) {
    const { data: model } = useEntity(modelEntity(modelId)) || {}
    if (!model)
        return null;

    return <><ModelCardContent model={model} /></>
}

function ModelCardContent({ model }) {
    const { data: voice } = useEntity(voiceEntity(model.voice_id)) || {}
    const [file, setFile] = useState();
    const [fileUrl, setFileUrl] = useState(undefined);
    const [duration, setDuration] = useState(undefined);
    const [modalOpen, setModalOpen] = useState(false);

    const backendCredit = useSelector(creditSelector);
    const pendingCost = useSelector(uploadsPendingCostSelector) || 0;
    const availableBalance = Math.max(0, backendCredit.topup - backendCredit.spendings - pendingCost);
    const requiredBalance = duration ? model.price_per_second * duration : 0;       // TODO

    const pendingStorage = useSelector(uploadsPendingStorageSelector) || 0;
    const quota = useSelector(subscriptionSelector)?.quota || 0;
    const backendStorage = useSelector(storageSelector) || 0;
    const availableStorage = Math.max(0, backendStorage.quota - backendStorage.usage - pendingStorage);
    const requiredStorage = file ? file.size : 0

    const [wantDenoise, setWantDenoise] = useState(false);
    const [wantDelay, setWantDelay] = useState(false);
    const [delayPreset, setDelayPreset] = useState(null);

    useEffect(() => {
        if (file) {
            setFileUrl(URL.createObjectURL(file));
        } else {
            setFileUrl(undefined);
            setDuration(undefined);
        }
    }, [file])

    if (!voice)
        return null;

    const sampleUrl = `${cdnUrl}/public/${voice.owner_id}/voices/${voice.id}/models/${model.id}/sample.wav`;

    const onFileDropped = (droppedFile) => {
        setFile(droppedFile);
    }

    const onClearFile = (e) => {
        setFile(undefined);
        e.stopPropagation();
    }

    const onModalClosed = () => setModalOpen(false);

    const onDenoiseChanged = (e) => setWantDenoise(e.target.checked)
    const onDelayChanged = (e) => setWantDelay(e.target.checked)

    const onPresetSelected = (e) => {
        const file = e.target.files[0];
        if (!file) {
            setDelayPreset(null);
            return;
        }
        var reader = new FileReader();
        reader.readAsText(file, 'UTF-8');
        reader.onload = readerEvent => {
            const content = readerEvent.target.result;
            try {
                const preset = JSON.parse(content);
                setDelayPreset(preset);
            } catch {
                setDelayPreset(null);
            }
        }
    }

    const onPreset = (e) => {
        var input = document.createElement('input');
        input.type = 'file';
        input.accept = ".nfa"
        input.onchange = onPresetSelected
        input.click();
    }

    const onSubmit = async () => {
        if (!file || !duration || !fileUrl)
            return;
        if (requiredBalance > availableBalance || requiredStorage > availableStorage) {
            setModalOpen(true);
        } else {
            let steps = [];
            if (wantDenoise) {
                steps.push(
                    {
                        algorithmType: 'dfn'
                    }
                );
            }
            steps.push(
                {
                    algorithmType: model.algorithm_id,
                    voiceOwnerId: voice.owner_id,
                    voiceId: voice.id,
                    modelId: model.id
                }
            )
            if (wantDelay) {
                let delayStep = {
                    algorithmType: "vst3",
                    pluginId: "rcr8",
                }
                if (delayPreset)
                    delayStep["pluginSettings"] = delayPreset;
                steps.push(delayStep);
            }
            console.log(steps);
            queueUpload({
                filename: file.name,
                localUrl: fileUrl,
                pendingStorage: file.size,
                pendingCost: requiredBalance,
                steps: steps
            });
            setFile(undefined);
            setFileUrl(undefined);
        }
    }

    return (
        <div style={{
            // background: "linear-gradient(90deg, rgba(1,198,217,1) 0%, rgba(0,208,202,1) 16%, rgba(0,163,161,1) 100%)",
            backgroundColor: "var(--nfai-darkcyan)", position: 'relative', width: '100%', height: '60pt', paddingLeft: '10pt', borderRadius: "5pt", borderStyle: "solid", borderColor: "var(--nfai-black)", borderWidth: "1pt"
        }}>
            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'flex-start', alignItems: 'center', height: '100%', width: '45%', gap: "20pt" }}>
                <div style={{ position: "absolute", left: "0%", display: "flex", alignItems: "center", width: "47%", height: "100%", gap: "5pt", paddingLeft: "5pt", paddingRight: "0pt" }}>
                    <div style={{ flexGrow: "1", height: "50pt" }}>
                        <div style={{
                            margin: "auto auto auto 0", position: "relative", display: "flex", justifyContent: "center", justifyItems: "center", alignItems: "center", width: "100%", height: "100%", backgroundColor: file ? "var(--nfai-yellow)" : "var(--nfai-lightcyan)", borderStyle: file ? "solid" : "dotted", borderColor: "var(--nfai-black)", borderRadius: "5pt", borderWidth: file ? "1pt" : "1.2pt"
                        }}>
                            <p key="drop_prompt" style={{ margin: "0", position: "absolute", width: "100%", fontSize: "10pt", opacity: file ? "1" : "0.75", textAlign: "center", fontWeight: file ? "bold" : "normal", pointerEvents: "none" }}>
                                {file ? "" : "drop files or click to select"}
                            </p>
                            <div key="drop_area" style={{ position: "absolute", width: "100%", height: "100%", borderRadius: "inherit" }}>
                                <FileDropArea onFileDropped={onFileDropped} noClick={file !== undefined} />
                            </div>
                            {
                                fileUrl && file &&
                                <>
                                    <AudioClip
                                        url={fileUrl}
                                        name={file.name}
                                        onLoadedMetadata={(audio) => setDuration(Math.ceil(audio.duration))}
                                        onCancel={onClearFile}
                                    />
                                </>
                            }
                        </div>
                    </div>
                </div>

                <div style={{ display: "flex", alignItems: "center", position: "absolute", left: "48%", width: "6%", height: "100%" }}>
                    <div style={{ display: "flex", flexFlow: "column", justifyContent: "flex-start", height: "50pt", gap: "5pt" }}>
                        <label style={{ fontSize: "8pt" }}>DENOISE</label>
                        <input type="checkbox" id="denoise" checked={wantDenoise} onChange={onDenoiseChanged} />
                    </div>
                </div>

                <div style={{ position: "absolute", left: "51%", display: "flex", alignItems: "center", width: "50%", height: "100%", gap: "5pt", paddingLeft: "15pt", paddingRight: "10pt" }}>
                    <div style={{ width: "100%", height: "50pt" }}>
                        <div style={{
                            background: "radial-gradient(circle, rgba(207,151,175,1) 0%, rgba(119,151,189,1) 100%)",
                            backgroundColor: "var(--nfai-dark-cyan)", margin: "auto auto auto 0", position: "relative", display: "flex", justifyContent: "center", justifyItems: "center", alignItems: "center", width: "100%", height: "100%", borderStyle: "solid", borderColor: "var(--nfai-black)", borderRadius: "5pt", borderWidth: "1pt"
                        }}>
                            {sampleUrl && <AudioClip url={sampleUrl} />}
                            <p style={{ margin: "0", position: "absolute", width: "100%", fontSize: "10pt", textAlign: "center", fontWeight: "bold", pointerEvents: "none" }}>
                                {model.name}
                            </p>
                            <div style={{ margin: "0", position: "absolute", top: "2pt", right: "3pt" }}>
                                <p style={{ margin: "0", fontWeight: "bold", fontSize: "9pt" }}>
                                    {model.algorithm_id.toUpperCase()}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", width: "60pt", paddingRight: "5pt", height: "50pt", flexFlow: "row", justifyContent: "flex-end" }}>
                        <div style={{ display: "flex", flexFlow: "column", justifyContent: "center", height: "100%" }}>
                            <div>
                                <div style={{ display: "flex", flexFlow: "column", height: "50pt", justifyContent: "space-between" }}>
                                    <label style={{ fontSize: "8pt" }}>RECIRCULATE</label>
                                    <input type="checkbox" id="rcr8" checked={wantDelay} onChange={onDelayChanged} style={{ backgroundColor: "yellow" }} />
                                    <button className="button" onClick={onPreset} style={{ backgroundColor: delayPreset ? "var(--nfai-green)" : "var(--nfai-yellow)", fontSize: "8pt", borderRadius: "3pt", borderStyle: "solid", borderWidth: "1pt", borderColor: "var(--nfai-black)" }}>PRESET</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {file && duration &&
                        <div style={{ display: "flex", width: "50pt", height: "50pt", flexFlow: "row", justifyContent: "flex-end" }}>
                            <div style={{ aspectRatio: "1", height: "100%" }} >
                                <button onClick={onSubmit} className="button" style={{ height: "100%", borderRadius: "5pt", borderStyle: "solid", borderWidth: "1pt", borderColor: "var(--nfai-black)" }}>
                                    SUBMIT
                                </button>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <InsufficientBalanceOrStorageModal
                isOpen={modalOpen}
                requiredBalance={requiredBalance}
                availableBalance={availableBalance}
                requiredStorage={requiredStorage}
                availableStorage={availableStorage}
                onClose={onModalClosed}
            />
        </div >
    );
}

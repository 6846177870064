import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import { meSelector } from "../../redux/globalSlice";
import { useEntity } from "../../redux/entity";
import { userEntity } from "../../redux/usersSlice";

export function HotText({ text, useMe = false, printFullnames = false }) {
    return text.split("___").map((segment, index) => {
        if (segment.startsWith("USER_")) {
            const userId = segment.substring(5);
            if (printFullnames)
                return <UserFullnameFragment key={index} userId={userId} useMe={useMe} />
            else
                return <UserIdFragment key={index} userId={userId} useMe={useMe}/>
        } else if (segment.startsWith("VOICE_")) {
            const voiceId = segment.substring(6);
            return <VoiceNameFragment key={index} voiceId={voiceId} />
        } else {
            return <span key={index}>{segment}</span>
        }
    })
}

export function UserHotText({ userId, useMe, printFullnames }) {
    return HotText({ text: `___USER_${userId}`, useMe: useMe, printFullnames: printFullnames })
}

export function VoiceHotText({ voiceId }) {
    return HotText({ text: `___VOICE_${voiceId}` })
}

function UserFullnameFragment({ userId, useMe }) {
    const navigate = useNavigate();
    const me = useSelector(meSelector);
    const { fullname } = useEntity(userEntity(userId))

    const onClick = (e) => {
        e.stopPropagation();
        navigate("/users/" + userId);
    }

    return (<span onClick={onClick} className="active" style={{ cursor: "pointer", fontWeight: "bold"}}>{userId === me.id && useMe ? "me" : fullname}</span>)
}

function UserIdFragment({ userId, useMe }) {
    const navigate = useNavigate();
    const me = useSelector(meSelector);

    const onClick = (e) => {
        e.stopPropagation();
        navigate("/users/" + userId);
    }

    return (<span onClick={onClick} className="active" style={{ cursor: "pointer", fontWeight: "bold"}}>{userId === me.id && useMe ? "me" : userId}</span>)
}

function VoiceNameFragment({ voiceId }) {
    const navigate = useNavigate();
    const { name } = useSelector(state => state.voices.entities[voiceId]?.data) || {}

    const onClick = (e) => {
        e.stopPropagation();
        navigate("/voices/" + voiceId);
    }

    return (<span onClick={onClick} className="active" style={{ cursor: "pointer", fontWeight: "bold"}}>{name}</span>)
}
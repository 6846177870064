import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../api/api";
import { addCollectionReducers } from "./collection";
import { addEntityCreateReducers, addEntityDeleteReducers, addEntityFetchReducers } from "./entity";
import { logOut } from "./globalSlice";


export const clipsSlice = createSlice({
    name: 'clips',
    initialState: {
        entities: {},
        collections: {
            filtered: {
            }
        }
    },
    extraReducers(builder) {
        addEntityCreateReducers(builder, clipCreateThunk, clipCreateDataSelector)
        addEntityFetchReducers(builder, clipFetchThunk)
        addEntityDeleteReducers(builder, clipDeleteThunk/*, clipDeleteExtraReducer*/)
        addCollectionReducers(builder, clipsByCurrentUserThunk, clipsByCurrentUserSelector, clipsByCurrentUserCreator)
        builder.addCase(logOut, (state, action) => {
            state.entities = {};
            state.collections = { filtered: {} }
        })
    }
})

export const clipCreateThunk = createAsyncThunk('clips/createClip', async (fileUrl, { getState, requestId }) => {
    // const token = tokenSelector(getState());
    // if (!token)
    //     return;
    return await Api.createClip({ fileUrl });
})
const clipCreateDataSelector = (payload) => payload.clip;
export const clipCreate = (url) => clipCreateThunk(url);

const clipFetchThunk = createAsyncThunk('clips/fetchClip', async (clipId, { getState, requestId }) => {
    const state = getState();
    const { currentRequestId } = state.clips.entities[clipId];
    if (currentRequestId !== requestId)
        return;
    return await Api.getClip(clipId);
})
export const clipEntity = (clipId) => {
    return {
        selector: (state) => state.clips.entities[clipId],
        action: clipFetchThunk(clipId)
    }
}

export const clipDeleteThunk = createAsyncThunk('clips/deleteClip', async (clipId, { getState, requestId }) => {
    const state = getState();
    const { currentRequestId } = state.clips.entities[clipId];
    if (currentRequestId !== requestId)
        return;
    return await Api.deleteClip(clipId);
})
const clipDeleteExtraReducer = (state, deletedTaskId) => {
    // TODO:
    // Object.entries(state.collections).forEach((collection) => {
    //     collection = collection.filter((taskId) => taskId !== deletedTaskId)
    // })
}
export const deleteClip = (clipId) => clipDeleteThunk(clipId)

const clipsByCurrentUserThunk = createAsyncThunk('clips/fetchClips', async (criteria, { getState, requestId }) => {
    const state = getState();
    const { currentRequestId } = state.clips.collections.filtered;
    if (currentRequestId !== requestId)
        return;
    return await Api.getClips();
})

const clipsByCurrentUserSelector = (_) => {
    return (state) => state.collections.byCurrentUser;
}
const clipsByCurrentUserCreator = (_, state, initialState) => {
    state.collections.byCurrentUser = initialState;
}
export const clipsByCurrentUser = {
    selector: (state) => state.clips.collections.byCurrentUser,
    action: clipsByCurrentUserThunk(),
}

export default clipsSlice.reducer
import { createSlice } from "@reduxjs/toolkit";
import { clipCreateThunk, clipDeleteThunk } from "./clipsSlice";
import { taskCreateThunk, taskDeleteThunk } from "./tasksSlice";


const initialState = {}

export const globalSlice = createSlice({
    name: 'global',
    initialState: initialState,
    reducers: {
        logIn: (state, action) => {
            state.currentUser = {
                id: action.payload.data.id,
                credit: action.payload.credit,
                storage: action.payload.storage,
                subscription: action.payload.subscription
            }
        },
        logOut: (state) => {
            delete state.currentUser;
        },
        setSubscription: (state, action) => {
            state.currentUser.subscription = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(clipCreateThunk.fulfilled, (state, action) => {
                const updatedCredit = action.payload.credit
                if (updatedCredit !== undefined)
                    state.currentUser.credit = updatedCredit;
                const updatedStorage = action.payload.storage;
                if (updatedStorage !== undefined)
                    state.currentUser.storage = updatedStorage;
            })
            .addCase(clipDeleteThunk.fulfilled, (state, action) => {
                const updatedCredit = action.payload.credit
                if (updatedCredit !== undefined)
                    state.currentUser.credit = updatedCredit;
                const updatedStorage = action.payload.storage;
                if (updatedStorage !== undefined)
                    state.currentUser.storage = updatedStorage;
            })
            .addCase(taskCreateThunk.fulfilled, (state, action) => {
                const updatedCredit = action.payload.credit
                if (updatedCredit !== undefined)
                    state.currentUser.credit = updatedCredit;
                const updatedStorage = action.payload.storage;
                if (updatedStorage !== undefined)
                    state.currentUser.storage = updatedStorage;
            })
            .addCase(taskDeleteThunk.fulfilled, (state, action) => {
                const updatedCredit = action.payload.credit
                if (updatedCredit !== undefined)
                    state.currentUser.credit = updatedCredit;
                const updatedStorage = action.payload.storage;
                if (updatedStorage !== undefined)
                    state.currentUser.storage = updatedStorage;
            })
    }
})

export const meSelector = state => {
    const id = state.global.currentUser?.id;
    return id ? state.users.entities[id] : undefined;
}
export const subscriptionSelector = state => state.global.currentUser?.subscription;
export const storageSelector = state => state.global.currentUser?.storage;
export const creditSelector = state => state.global.currentUser?.credit;

export const { logIn, logOut, setSubscription } = globalSlice.actions

export default globalSlice.reducer
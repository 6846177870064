import { createSlice } from "@reduxjs/toolkit";


export const plansSlice = createSlice({
    name: 'plans',
    initialState: {
        entities: {},
        collections: {
            available: []
        }
    },
    reducers: {
        setPlans: (state, action) => {
            const plans = action.payload;
            plans.forEach((plan) => {
                state.entities[plan.id] = plan
            })
            state.collections.available = plans.map((plan) => plan.id)
        }
    }
})

export const planSelector = (planId) => {
    return (state) => state.plans.entities[planId];
}

export const availablePlansSelector = (state) => state.plans.collections.available;

export const { setPlans } = plansSlice.actions
export default plansSlice.reducer
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../api/api";
import { addEntityCreateReducers, addEntityDeleteReducers, addEntityFetchReducers } from "./entity";
// import { addPaginatedCollectionReducers } from "./paginatedCollection";
import { addInfiniteCollectionReducers } from "./infiniteCollection";
import { logOut } from "./globalSlice";

export const TaskStatus = {
    Pending: "pending",
    Queued: "queued",
    Processing: "processing",
    Succeeded: "succeeded",
    Failed: "failed",
}


export const tasksSlice = createSlice({
    name: 'tasks',
    initialState: {
        entities: {},
        collection: {
            // pages: [],
            ids: [],
            haveMore: true,
        },
    },
    extraReducers(builder) {
        addEntityCreateReducers(
            builder,
            taskCreateThunk,
            taskCreateDataSelector,
            taskCreateReducerIntercept)
        addEntityFetchReducers(builder, taskFetchThunk)
        addEntityDeleteReducers(
            builder,
            taskDeleteThunk,
            taskDeleteReducerIntercept)
        // addPaginatedCollectionReducers(
        //     builder,
        //     tasksCollectionThunk,
        //     tasksCollectionSelector,
        //     tasksCollectionReducerIntercept,
        //     tasksCollectionRefreshAction
        // )
        addInfiniteCollectionReducers(
            builder,
            tasksCollectionThunk,
            tasksCollectionSelector,
            tasksCollectionReducerIntercept,
            tasksCollectionRefreshAction
        )
        builder.addCase(logOut, (state, action) => {
            state.entities = {};
            state.collection = {
                // pages: [],
                ids: [],
                haveMore: true,
            };
        })
    }
})

export const taskCreateThunk = createAsyncThunk('tasks/createTask', async (arg, { getState, requestId }) => {
    return await Api.createTask(arg);
})
const taskCreateDataSelector = (payload) => payload.task;
const taskCreateReducerIntercept = (state, task) => {
    const collection = tasksCollectionSelector(state);
    if (collection.currentRequestId)
        return;
    collection.ids.unshift(task.id);
}
export const taskCreate = ({ clipId, steps }) => taskCreateThunk({ clipId, steps });

export const taskFetchThunk = createAsyncThunk('tasks/fetchTask', async (taskId, { getState, requestId }) => {
    const state = getState();
    const { currentRequestId } = state.tasks.entities[taskId];
    if (currentRequestId !== requestId)
        return;
    return await Api.getTask(taskId);
})
export const taskEntity = (taskId) => {
    return {
        selector: (state) => state.tasks.entities[taskId],
        action: taskFetchThunk(taskId)
    }
}

export const taskDeleteThunk = createAsyncThunk('tasks/deleteTask', async (taskId, { getState, requestId }) => {
    const state = getState();
    const { currentRequestId } = state.tasks.entities[taskId];
    if (currentRequestId !== requestId)
        return;
    return await Api.deleteTask(taskId);
})
// const taskDeleteReducerIntercept = (state, deletedTaskId) => {
//     state.collection.pages.forEach((page) => {
//         if (page.ids.includes(deletedTaskId))
//             page.ids = page.ids.filter((id) => id !== deletedTaskId);
//     })
// }
const taskDeleteReducerIntercept = (state, deletedTaskId) => {
    const collection = tasksCollectionSelector(state);
    collection.ids = collection.ids.filter((id) => id !== deletedTaskId);
}
export const deleteTask = (taskId) => taskDeleteThunk(taskId)

/*
const tasksCollectionThunk = createAsyncThunk('tasks/fetchTasks', async ({ link, limit }, { getState, requestId }) => {
    const state = getState();
    const { currentRequestId } = state.tasks.collection;
    if (currentRequestId !== requestId)
        return;
    // if (haveMore === 0)
    //     return;
    const response = await Api.getTasks({ link, limit });
    return response;
})
const tasksCollectionSelector = (state) => state.collection;
const tasksCollectionRefreshAction = { type: "tasks/refresh", payload: null }
const tasksCollectionReducerIntercept = (actionCreator, state, action) => {
    if (actionCreator === tasksCollectionThunk.fulfilled) {
        const tasks = action.payload.data;
        tasks.forEach((task) => {
            const entity = state.entities[task.id];
            if (entity?.currentRequestId !== undefined)
                return;
            state.entities[task.id] = {
                data: task,
                lastFetched: Date.now()
            }
        })
    }
}
export const tasksCollection = (desc, pageSize, numPrefetchedPages) => {
    return {
        selector: (state) => state.tasks.collection,
        action: tasksCollectionThunk,
        desc,
        pageSize,
        numPrefetchedPages,
        refreshAction: tasksCollectionRefreshAction
    }
}
*/

const tasksCollectionThunk = createAsyncThunk('tasks/fetchTasks', async ({ limit, desc }, { getState, requestId }) => {
    const state = getState();
    const { currentRequestId } = state.tasks.collection;
    if (currentRequestId !== requestId)
        return;
    // if (haveMore === 0)
    //     return;
    const link = state.tasks.collection.ids[state.tasks.collection.ids.length - 1];
    return await Api.getTasks({ link, limit, desc });
})
const tasksCollectionSelector = (state) => state.collection;
const tasksCollectionRefreshAction = { type: "tasks/refresh", payload: null }
const tasksCollectionReducerIntercept = (actionCreator, state, action) => {
    if (actionCreator === tasksCollectionThunk.fulfilled) {
        const tasks = action.payload.data;
        tasks.forEach((task) => {
            const entity = state.entities[task.id];
            if (entity?.currentRequestId !== undefined)
                return;
            state.entities[task.id] = {
                data: task,
                lastFetched: Date.now()
            }
        })
    }
}
export const tasksCollection = (desc, numPrefetchedItems) => {
    return {
        selector: (state) => state.tasks.collection,
        desc,
        numPrefetchedItems,
        fetchMoreThunk: tasksCollectionThunk,
        refreshAction: tasksCollectionRefreshAction
    }
}

export default tasksSlice.reducer
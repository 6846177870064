import { useSelector } from "react-redux"
import { currentUploadSelector, failedUploadsSelector, pendingUploadsSelector } from "../../redux/uploadsSlice"
import Modal from "../modal/Modal";
import { Alert } from "../modal/Alert";


export default function UploadsModal({ isOpen, onClose }) {
    const currentUpload = useSelector(currentUploadSelector);
    const pendingUploads = useSelector(pendingUploadsSelector);
    const failedUploads = useSelector(failedUploadsSelector);

    // console.log(currentUpload);
    // console.log(pendingUploads);
    // console.log(failedUploads);

    if (!currentUpload && !pendingUploads && !failedUploads) {
        return (
            <Alert
                hasCloseButton={true}
                isOpen={isOpen}
                onClose={() => {}}
                header={"UPLOADS"}
            >
                <div style={{ width: "300pt", height: "300pt" }}>
                    <p>No pending uploads</p>
                </div>
            </Alert>
        )
    }

    // filename: file.name,
    // localUrl: fileUrl,
    // pendingStorage: file.size,
    // pendingCost: requiredBalance,
    // modelId: model.id
    // progress: 

    // if (currentUpload)
    //     console.log("RPG: " + currentUpload.progress);

    return (
        <Alert isOpen={isOpen}>
            <div style={{ width: "300pt", height: "300pt" }}>
                {
                    currentUpload && <Upload upload={currentUpload} />
                }
            </div>
        </Alert>
    )
}

function Upload({ upload, isCurrent }) {
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <p style={{ margin: "0", fontSize: "10pt", width: "100pt" }}>{upload.filename}</p>
            <div style={{ backgroundColor: "var(--nfai-cyan)", width: "100pt" }}>
                <p style={{ margin: "auto", textAlign: "center" }}>{isCurrent ? upload.progress : "waiting"}</p>
                <div style={{ backgroundColor: "var(--nfai-yellow)", width: `${upload.progress}%` }}>
                </div>
            </div>
        </div>
    );
}
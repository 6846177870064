import { useCallback, useEffect, useState } from "react";
import { currentAudio, playAudio } from "../../App";
import pause_png from "../../assets/pause.png"
import play_png from "../../assets/play.png"
import cross_png from "../../assets/cross.png";
import download_png from "../../assets/download.png";
import warning_png from "../../assets/warning.png";
import { MoonLoader } from "react-spinners";


export default function AudioClip({ url, name, onLoadedMetadata, onDownload, onCancel }) {
    const [audio, setAudio] = useState(new Audio());

    useEffect(() => {
        setAudio(new Audio(url));
    }, [url])

    const [mouseDown, setMouseDown] = useState(false);
    const [duration, setDuration] = useState(undefined);
    const [targetTime, setTargetTime] = useState(undefined);
    const [currentTime, setCurrentTime] = useState(undefined);
    // const [seeking, setSeeking] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [canPlay, setCanPlay] = useState(false);
    const [error, setError] = useState(false);

    const listener = useCallback((event) => {
        if (event.type === "loadedmetadata") {
            onLoadedMetadata?.(audio);
            setDuration(audio.duration);
        } else if (event.type === "canplay") {
            setCanPlay(true);
        } else if (event.type === "timeupdate") {
            setCurrentTime(audio.currentTime);
        } else if (event.type === "play" || event.type === "pause") {
            setPlaying(!audio.paused);
            // } else if (event.type === "seeking") {
            //     setSeeking(true);
        } else if (event.type === "seeked") {
            setCurrentTime(audio.currentTime);
            setTargetTime(undefined);
        } else if (event.type === "error") {
            setError(true);
        }
    }, [audio, onLoadedMetadata]);

    useEffect(() => {
        audio.addEventListener("loadedmetadata", listener);
        audio.addEventListener("canplay", listener);
        audio.addEventListener("play", listener);
        audio.addEventListener("pause", listener);
        audio.addEventListener("timeupdate", listener);
        // player.addEventListener("seeking", listener);
        audio.addEventListener("seeked", listener);
        audio.addEventListener("error", listener);
        // audio.addEventListener("stalled", listener);
        // audio.addEventListener("suspend", listener);

        return () => {
            audio.removeEventListener("loadedmetadata", listener);
            audio.removeEventListener("canplay", listener);
            audio.removeEventListener("play", listener);
            audio.removeEventListener("pause", listener);
            audio.removeEventListener("timeupdate", listener);
            // player.removeEventListener("seeking", listener);
            audio.removeEventListener("seeked", listener);
            audio.removeEventListener("error", listener);
            // audio.removeEventListener("stalled", listener);
            // audio.removeEventListener("suspend", listener);
            if (currentAudio === audio) {
                audio.pause();
                playAudio(undefined);
            }
        }
    }, [audio, url, listener]);

    function calcProportion(e) {
        const clientRect = e.target.getBoundingClientRect();
        const proportionRaw = (e.clientX - clientRect.x) / clientRect.width;
        return Math.max(0.0, Math.min(1.0, proportionRaw));
    }

    const onMouseDown = (e) => {
        e.stopPropagation();
        setMouseDown(true);
        const proportion = calcProportion(e);
        if (duration)
            setTargetTime(duration * proportion);
    }

    const onMouseMove = (e) => {
        e.stopPropagation();
        if (mouseDown) {
            const proportion = calcProportion(e);
            if (duration)
                setTargetTime(duration * proportion);
        }
    }

    const onMouseUp = (e) => {
        e.stopPropagation();
        setMouseDown(false);
        if (duration)
            audio.currentTime = targetTime;
        else
            setTargetTime(undefined);
    }

    const onTogglePlay = (e) => {
        e.stopPropagation();
        if (currentAudio === audio)
            playAudio(undefined);
        else
            playAudio(audio);
    }

    const time = targetTime ?? currentTime;
    const pointerProportion = (time && duration) ? 100.0 * time / duration : undefined;

    if (!audio)
        return null;

    return (
        <div
            style={{ display: "flex", alignItems: "center", position: "relative", width: "100%", height: "100%" }}
        >
            <div
                style={{ position: "relative", width: "100%", height: "100%" }}
                onMouseDown={onMouseDown}
                onMouseMove={onMouseMove}
                onMouseUp={onMouseUp}
            >
                {
                    pointerProportion &&
                    <div style={{ pointerEvents: "none", backgroundColor: "black", opacity: "0.1", position: "absolute", left: "0", width: `${pointerProportion}%`, height: "100%" }}>
                    </div>
                }
                <div style={{ pointerEvents: "none", display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
                    <p style={{ position: "absolute", whiteSpace: "nowrap", userSelect: "none", pointerEvents: "none", fontSize: "10pt", margin: "0pt 30pt 0pt 30pt" }}>{name}</p>
                </div>
            </div>
            {
                error ?
                    <img
                        src={warning_png}
                        alt="ERR"
                        style={{ pointerEvents: "none", position: "absolute", left: "8pt", width: '16pt', height: '16pt' }}
                    />
                    :
                    (canPlay ?
                        <img
                            src={playing ? pause_png : play_png}
                            onClick={onTogglePlay}
                            onMouseDown={() => false}
                            onMouseMove={() => false}
                            onMouseUp={() => false}
                            alt="PLAY"
                            style={{ pointerEvents: mouseDown ? "none" : "all", cursor: "pointer", position: "absolute", left: "8pt", width: '16pt', height: '16pt' }}
                        /> :
                        <div style={{ position: "absolute", left: "8pt", width: '16pt', height: '16pt' }}><MoonLoader size="16px" /></div>
                    )
            }
            <div style={{ pointerEvents: mouseDown ? "none" : "all", cursor: "pointer", display: "flex", position: "absolute", top: "0pt", right: "5pt", height: "10pt", gap: "5pt" }}>
                {
                    onDownload &&
                    <div onClick={onDownload} style={{ width: "10pt", height: "10pt" }}>
                        <img src={download_png} alt="EXIT" style={{ width: "100%", height: "100%" }} />
                    </div>
                }
                {
                    onCancel &&
                    <div onClick={onCancel} style={{ width: "10pt", height: "10pt" }}>
                        <img src={cross_png} alt="EXIT" style={{ width: "100%", height: "100%" }} />
                    </div>
                }
            </div>
        </div>
    )
}
import { createSlice } from "@reduxjs/toolkit";
import { logOut } from "./globalSlice";


export const uploadsSlice = createSlice({
    name: 'uploads',
    initialState: {
        current: undefined,
        pending: [],
        failed: [],
    },
    reducers: {
        pushPendingUpload: (state, action) => {
            state.pending.unshift(action.payload);
        },
        popPendingUpload: (state, action) => {
            state.current = state.pending.pop();
        },
        setUploadProgress: (state, action) => {
            state.current.progress = action.payload;
        },
        setUploadPendingStorage: (state, action) => {
            state.current.pendingStorage = action.payload;
        },
        pushFailedUpload: (state, action) => {
            state.failed.push(action.payload);
        },
        clearFailedUploads: (state, action) => {
            state.failed = [];
        },
    },
    extraReducers(builder) {
        builder.addCase(logOut, (state, action) => {
            state.current = undefined;
            state.pending = [];
            state.failed = [];
        })
    }
})

export const pendingUploadsSelector = state => state.uploads.pending;
export const currentUploadSelector = state => state.uploads.current;
export const failedUploadsSelector = state => state.uploads.failed;
export const uploadsPendingCostSelector = state => {
    return state.uploads.pending.reduce(
        (totalCost, upload) => totalCost + upload.pendingCost,
        state.uploads.current?.pendingCost ?? 0
    );
}
export const uploadsPendingStorageSelector = state => {
    return state.uploads.pending.reduce(
        (totalSize, upload) => totalSize + upload.pendingStorage,
        state.uploads.current?.pendingStorage ?? 0
    );
}

export const { pushPendingUpload, popPendingUpload, setUploadProgress, pushFailedUpload, clearFailedUploads, setUploadPendingStorage } = uploadsSlice.actions

export default uploadsSlice.reducer
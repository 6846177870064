import { configureStore } from '@reduxjs/toolkit'

import globalReducer from './globalSlice'
import plansReducer from './plansSlice'
import productsReducer from './productsSlice'
import usersReducer from './usersSlice'
import voicesReducer from './voicesSlice'
import modelsReducer from './modelsSlice'
import clipsReducer from './clipsSlice'
import tasksReducer from './tasksSlice'
import uploadsReducer from './uploadsSlice'


export const store = configureStore({
  reducer: {
    global: globalReducer,
    plans: plansReducer,
    products: productsReducer,
    users: usersReducer,
    voices: voicesReducer,
    models: modelsReducer,
    clips: clipsReducer,
    tasks: tasksReducer,
    uploads: uploadsReducer,
  }
})

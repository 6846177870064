import { useDispatch } from "react-redux";
import favorite_off from "../../assets/favorite_off.png";
import favorite_on from "../../assets/favorite_on.png";
import { useEntity } from "../../redux/entity";
import { likeVoice, unlikeVoice, voiceEntity } from "../../redux/voicesSlice";

export default function VoiceLikes({ voiceId }) {
    const { data: voice, currentRequestId: pendingRequest } = useEntity(voiceEntity(voiceId)) || {};
    const dispatch = useDispatch();

    const liked = voice?.liked_by_me;

    const likeUnlike = async () => {
        if (pendingRequest)
            return;
        if (liked)
            dispatch(unlikeVoice(voiceId));
        else
            dispatch(likeVoice(voiceId));
    };

    return (
        <>
            <div onClick={pendingRequest ? undefined : likeUnlike} className="active" style={{ /*animationName: pendingRequest ? "spin" : "none", animationDuration: "1000ms", animationIterationCount: "infinite", */ backgroundColor: "transparent", display: 'flex', position: 'relative', cursor: 'pointer' }}>
                <img src={liked ? favorite_on : favorite_off} alt="LIKE" style={{}} />
            </div>
        </>
    )
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { Provider } from 'react-redux'
import { store } from './redux/store';
import { Authenticator } from '@aws-amplify/ui-react';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <Authenticator
        signUpAttributes={["email"]}
        /*socialProviders={["google"]}*/
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}>
        <Authenticator.Provider>
          <App />
        </Authenticator.Provider>
      </Authenticator>
    </Provider>
  </BrowserRouter>
  // </React.StrictMode>
);

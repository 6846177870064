import { useSelector } from "react-redux";
import { uploadManagerAddListener, uploadManagerRemoveListener } from "../../uploadManager";
import toast from "react-hot-toast";
import { useCallback, useEffect, useRef } from "react";
import warning_png from '../../assets/warning.png';
import { currentUploadSelector, failedUploadsSelector, pendingUploadsSelector } from "../../redux/uploadsSlice";


export default function UploadIndicator({ onClick }) {
    const pendingUploads = useSelector(pendingUploadsSelector)
    const failedUploads = useSelector(failedUploadsSelector)
    const currentUpload = useSelector(currentUploadSelector);

    const canvasRef = useRef(null);

    const uploadManagerListener = useCallback(({ result, filename, errorMessage }) => {
        if (result === "upload_succeeded")
            toast.success(`${filename} queued for conversion`)
        else if (result === "upload_aborted")
            toast.error(`${filename} upload aborted`)
        else if (result === "upload_failed")
            toast.error(`${filename} upload failed: ${errorMessage}`)
    }, []);

    useEffect(() => {
        uploadManagerAddListener(uploadManagerListener);
        return () => uploadManagerRemoveListener(uploadManagerListener);
    }, [uploadManagerListener])

    useEffect(() => {
        if (!currentUpload)
            return
        const canvas = canvasRef.current
        const ctx = canvas.getContext('2d')
        ctx.fillStyle = "#1a1a1a";
        ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height)
        ctx.lineWidth = 9;
        const radius = Math.min(ctx.canvas.width, ctx.canvas.height) / 2 - ctx.lineWidth / 2
        ctx.strokeStyle = "#363636";
        ctx.beginPath();
        ctx.arc(ctx.canvas.width / 2, ctx.canvas.height / 2, radius, 0, 2 * Math.PI);
        ctx.stroke();
        ctx.strokeStyle = "#1a9faf";
        ctx.beginPath();
        ctx.arc(ctx.canvas.width / 2, ctx.canvas.height / 2, radius, -Math.PI / 2, -Math.PI / 2 + 2 * Math.PI * currentUpload.progress / 100);
        ctx.stroke();
    }, [currentUpload])

    const numUploads = pendingUploads.length + (currentUpload ? 1 : 0);
    const numFailedUploads = failedUploads.length;

    if (numUploads === 0 && numFailedUploads === 0)
        return null;

    return (
        <div onClick={onClick} style={{ cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center", position: "relative", width: "16pt", height: "16pt" }}>
            {
                numUploads > 0 &&
                (
                    <>
                        <canvas ref={canvasRef} width="100" height="100" style={{ position: "absolute", height: "100%", width: "100%" }} />
                        <p style={{ margin: "0", position: "absolute", fontSize: "11pt", color: "var(--nfai-cyan)" }}>{numUploads}</p>)
                    </>
                )}
            {
                numFailedUploads > 0 &&
                <div style={{ display: "flex", position: "absolute", right: "0", top: "0", width: `${numUploads > 0 ? 30 : 100}%`, aspectRatio: "1" }}>
                    <img src={warning_png} alt="!" />
                </div>

            }
        </div>
    )
}
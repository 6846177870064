import { useSelector } from "react-redux";
import { secondsToHhMmSs } from "../../utils.js";
import { uploadsPendingCostSelector } from "../../redux/uploadsSlice.js"
import { creditSelector, meSelector } from "../../redux/globalSlice.js"
import { useNavigate } from "react-router-dom";


export default function BalanceIndicator() {
    const navigate = useNavigate();
    const user = useSelector(meSelector);
    const pendingCost = useSelector(uploadsPendingCostSelector);
    const backendCredit = useSelector(creditSelector);

    if (pendingCost === undefined || backendCredit === undefined)
        return null;

    const balance = backendCredit.topups - backendCredit.spendings - pendingCost;

    const costPerSecond = 100;          // TODO: remove when per-model price is introduced
    const seconds = balance / costPerSecond;

    let color = "var(--nfai-yellow)";
    if (seconds < 300)
        color = "var(--nfai-red)";
    else if (seconds < 1800)
        color = "var(--nfai-yellow)";
    else
        color = "var(--nfai-green)";

    const onClick = (e) => {
        e.stopPropagation();
        navigate("/users/" + user.id);
    }

    return (
        <div
            className="active"
            style={{ display: "flex", alignContent: "flex-start", gap: "4pt", alignItems: "center" }}
            onClick={onClick}
        >
            <div className="stopwatch_icon" style={{ backgroundColor: color, width: "18pt", height: "18pt", margin: "0pt 0pt 3pt 0pt" }}></div>
            <p className="active" style={{ color: color }}>{secondsToHhMmSs(seconds)}</p>
        </div>
    )
}
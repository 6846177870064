import { useDispatch, useSelector } from "react-redux";

/**
 * Infinte collection is a collection that can be fetched gradually 
 * The continuity is ensured by providing link token which is the highest returned
 * id (or other column value by which the results are sorted)
 * The data-providing endpoint must comply with this logic! 
 * Each request includes the link token (optional) and limit (mandatory)
 * When a link token is attached to a request only records with id higher
 * than link will be returned. This achieves some important features that
 * the naive paging using OFFSET and LIMIT SQL keywords cannot offer, namely:
 * - scalability for big collections
 * - no duplicates in case of new record inserts while the collection is iterated
 * If no link token is provided the first records are returned.
 * The main drawback of the used logic (compared to OFFSET/LIMIT) is that the
 * number of pages cannot be determined and will only be known once the end
 * of the collection has been reached.
 * 
 * The format of the response is expected to be:
 * {
 *      data: [],           // array of collection entities (must include id field) or entity ids
 *      haveMore: boolean   // whether there are more records in the collection
 * }
 * 
 * It is assumed the the collection key already exists in the store when the thunk is dispatched
 * It should be initialized as:
 * {
 *      ids: [],
 *      haveMore: true,
 * }
 * 
 * Arguments:
 * @param {*} builder - reference to ActionReducerMapBuilder
 * @param {*} thunk - async action creator (thunk) for fetching collection batches
 * @param {*} collectionSelector - collection selector, not that this is slice-scoped selector, not store-scoped one
 * @param {*} reducerIntercept - allows for doing extra reducer work such as storing entities
 * @param {*} refreshAction - action for refreshing the collection, supposed to clear all cached pages
 */

export function addInfiniteCollectionReducers(
    builder,
    thunk,
    collectionSelector,
    reducerIntercept,
    refreshAction,
    idSelector = (item) => item.id,
) {
    builder.addCase(thunk.pending, (state, action) => {
        const collection = collectionSelector(state);
        if (collection.currentRequestId)
            return;
        collection.currentRequestId = action.meta.requestId;
        reducerIntercept?.(thunk.pending, state, action);
    })
    builder.addCase(thunk.fulfilled, (state, action) => {
        const collection = collectionSelector(state);
        if (collection.currentRequestId !== action.meta.requestId)
            return;
        collection.currentRequestId = undefined;
        const items = action.payload.data;
        let ids = items.map(item => idSelector(item))
        collection.ids = collection.ids.concat(ids);
        // console.log(collection.ids)
        collection.haveMore = action.payload.haveMore;
        collection.lastFetched = Date.now();
        reducerIntercept?.(thunk.fulfilled, state, action);
    })
    builder.addCase(thunk.rejected, (state, action) => {
        const collection = collectionSelector(state);
        if (collection.currentRequestId !== action.meta.requestId)
            return;
        collection.currentRequestId = undefined;
        reducerIntercept?.(thunk.rejected, state, action);
    })
    builder.addCase(refreshAction, (state, action) => {
        const collection = collectionSelector(state);
        if (collection.currentRequestId)
            return;
        collection.ids = [];
        collection.haveMore = true;
    })
}

export function useInfiniteCollection({
    selector,
    desc,
    numPrefetchedItems,
    fetchMoreThunk,
    refreshAction
}) {
    const dispatch = useDispatch();

    const { ids, haveMore, currentRequestId } = useSelector(selector);

    const refresh = () => {
        dispatch(refreshAction);
    }

    const fetchMore = () => {
        dispatch(fetchMoreThunk({ 
            desc, 
            limit: numPrefetchedItems 
        }))
    }

    return {
        ids,
        haveMore,
        currentRequestId,
        numPrefetchedItems,
        fetchMore,
        refresh
    };
}

import { useParams } from "react-router-dom";
import checkmark_png from "../../assets/checkmark.png"
import stopwatch_png from "../../assets/stopwatch2.png"
import { uploadsPendingCostSelector, uploadsPendingStorageSelector } from "../../redux/uploadsSlice";
import { creditSelector, setSubscription, storageSelector, subscriptionSelector } from "../../redux/globalSlice";
import { useDispatch, useSelector } from "react-redux";
import { bytesToString, secondsToHhMmSs } from "../../utils";
import mic_png from '../../assets/mic.png'
import Api from "../../api/api";
import { useState } from "react";
import { MoonLoader } from "react-spinners";
import { availablePlansSelector, planSelector } from "../../redux/plansSlice";
import { availableProductsSelector, productSelector } from "../../redux/productsSlice";
import { current } from "@reduxjs/toolkit";

/**
 * if (on free plan):
 *      => show current (free) plan
 *      => show paid plan selection
 * else (on paid plan):
 *   if (subscription active):
 *      => show current subscription
 *      => show manage button
 *   else if (subscription cancelled)
 *      => show current subscription
 *      => show info when it ends
 *      => show resume button
 *      => show manage button
 *   else if (subscription past_due)
 *      => show current subscription
 *      => show info that payment failed (payment attempt type)
 *      => show manage button
 *   else if (subscription unpaid)
 *      => show current subscription
 *      => show info that payment failed (payment attempt type)
 *      => show info that we will block access
 *      => show manage button
 */
const costPerSecond = 100;          // TODO: remove when per-model price is introduced

export default function User() {
    let { userId } = useParams();
    const dispatch = useDispatch();
    const currentSubscription = useSelector(subscriptionSelector);

    const onFocus = () => {
        window.removeEventListener("focus", onFocus);
        Api.getSubscription()
            .then((updatedSubscription) => dispatch(setSubscription(updatedSubscription)))
            .catch((err) => console.log(err))
    }

    const onWentToStore = () => {
        window.addEventListener("focus", onFocus);
    }
    return (
        <div style={{ position: "absolute", marginTop: "auto", marginBottom: "20pt", top: "0pt", bottom: "20pt", width: "100%", justifyContent: "center", overflow: "scroll" }}>
            <div style={{ display: "flex", flexFlow: "column", alignItems: "center", justifyContent: "center", marginTop: "20pt", gap: "30pt" }}>
                <PlanSection
                    currentSubscription={currentSubscription}
                    onWentToStore={onWentToStore}
                />
                <TopupSection />
            </div>
        </div>
    );
}

function PlanSection({ currentSubscription, onWentToStore }) {
    return (
        <div style={{ display: "flex", flexFlow: "column", gap: "12pt", alignItems: "center" }}>
            YOUR PLAN:
            {
                currentSubscription ?
                    <CurrentPlanPaid
                        currentSubscription={currentSubscription}
                        onWentToStore={onWentToStore}
                    /> :
                    <CurrentPlanFree />
            }
        </div >
    )
}

function CurrentPlanFree() {
    return (
        <div
            style={{
                display: "flex",
                flexFlow: "column",
                alignItems: "center",
                gap: "12pt"
            }}
        >
            <div style={{ display: "flex", gap: "30pt" }}>
                <div
                    style={{
                        display: "flex",
                        width: "120pt",
                        aspectRatio: "0.66",
                        alignItems: "center"
                    }}>
                    <FreePlan />
                </div>
                <Statistics />
            </div>
            <PlanUpgrade />
        </div>
    )
}

function CurrentPlanPaid({ currentSubscription, onWentToStore }) {
    const dispatch = useDispatch();
    const [busy, setBusy] = useState(false);
    const plan = useSelector(planSelector(currentSubscription.plan_id))
    if (!plan)
        return null;

    const onCancelClick = () => {
        if (busy)
            return;
        setBusy(true);
        Api.cancelSubscription()
            .then((updatedSubscription) => {
                dispatch(setSubscription(updatedSubscription))
            })
            .catch((err) => console.log(err))
            .finally(() => setBusy(false))
    }

    const onResumeClick = () => {
        if (busy)
            return;
        setBusy(true);
        Api.resumeSubscription()
            .then((updatedSubscription) => {
                dispatch(setSubscription(updatedSubscription))
            })
            .catch((err) => console.log(err))
            .finally(() => setBusy(false))
    }

    const onChangePlanClick = () => {
        if (busy)
            return;
        // Check if the cached signed link is still valid
        // For Lemonsqueezy these links are valid for 6 hours and we want
        // to avoid hitting the API unless we have to, to avoid throttling
        const urlPath = currentSubscription.manage_subscription_url ?? currentSubscription.customer_portal_url;
        if (urlPath) {
            const url = new URL(urlPath);
            const linkParams = new URLSearchParams(url.search);
            const linkExpirationTimestamp = linkParams.get("expires") * 1000;
            const acceptableExpirationTimestamp = (new Date()).getTime() + 60 * 1000;     // 60 seconds from now
            if (linkExpirationTimestamp > acceptableExpirationTimestamp) {
                window.open(urlPath, "_blank")
                return;
            }
        }
        setBusy(true);
        Api.getSubscription()
            .then((upToDateSubscription) => {
                console.log(upToDateSubscription);
                dispatch(setSubscription(upToDateSubscription))
                onWentToStore();
                const urlPath = upToDateSubscription.manage_subscription_url ?? upToDateSubscription.customer_portal_url;
                window.open(urlPath, "_blank")
            })
            .catch((err) => console.log(err))
            .finally(() => setBusy(false))
    }

    const onUpdatePaymentMethodClick = () => {
        if (busy)
            return;
        // Check if the cached signed link is still valid
        // For Lemonsqueezy these links are valid for 6 hours and we want
        // to avoid hitting the API unless we have to, to avoid throttling
        const urlPath = currentSubscription.update_payment_method_url ?? currentSubscription.customer_portal_url;
        if (urlPath) {
            const url = new URL(urlPath);
            const linkParams = new URLSearchParams(url.search);
            const linkExpirationTimestamp = linkParams.get("expires") * 1000;
            const acceptableExpirationTimestamp = (new Date()).getTime() + 60 * 1000;     // 60 seconds from now
            if (linkExpirationTimestamp > acceptableExpirationTimestamp) {
                window.open(urlPath, "_blank")
                return;
            }
        }
        setBusy(true);
        Api.getSubscription()
            .then((upToDateSubscription) => {
                console.log(upToDateSubscription);
                dispatch(setSubscription(upToDateSubscription))
                onWentToStore();
                const urlPath = upToDateSubscription.update_payment_method_url ?? upToDateSubscription.customer_portal_url;
                window.open(urlPath, "_blank")
            })
            .catch((err) => console.log(err))
            .finally(() => setBusy(false))
    }

    let subscriptionOptionsJsx;
    switch (currentSubscription.status) {
        case "active": {
            subscriptionOptionsJsx = (
                <div style={{ display: "flex", gap: "24pt" }}>
                    <button className="button active" onClick={onChangePlanClick}>
                        CHANGE PLAN
                    </button>
                    <button className="button active" onClick={onUpdatePaymentMethodClick}>
                        UPDATE PAYMENT METHOD
                    </button>
                    <button className="button active" style={{ backgroundColor: "var(--nfai-orange)" }} onClick={onCancelClick}>
                        CANCEL SUBSCRIPTION
                    </button>
                </div>
            );
            break;
        }
        case "cancelled": {
            subscriptionOptionsJsx = (
                <div style={{ display: "flex", flexFlow: "column", gap: "12pt", alignItems: "center", justifyContent: "center" }}>
                    <div>
                        Your subscription has been cancelled. It will remain active until {new Date(currentSubscription.ends_at).toLocaleString()}
                    </div>
                    <div>
                        <button className="button active" onClick={onResumeClick}>
                            RESUME SUBSCRIPTION
                        </button>
                    </div>
                </div>
            )
            break;
        }
        case "pastdue": {
            subscriptionOptionsJsx = (
                <div style={{ display: "flex", flexFlow: "column", gap: "12pt", alignItems: "center", justifyContent: "center", maxWidth: "500pt" }}>
                    <div style={{textAlign: "center"}}>
                        Your subscription is past due. Make sure you have enough funds on your account or change the payment method
                    </div>
                    <button className="button active" onClick={onUpdatePaymentMethodClick}>
                        UPDATE PAYMENT METHOD
                    </button>
                </div>
            );
            break;
        }
        case "unpaid": {
            subscriptionOptionsJsx = (
                <div style={{ display: "flex", flexFlow: "column", gap: "12pt", alignItems: "center", justifyContent: "center", maxWidth: "500pt" }}>
                    <div style={{textAlign: "center"}}>
                        Your subscription will be terminated soon! We have not been able to collect the payment despite multiple attempts. Please review your payment method.
                    </div>
                    <button className="button active" onClick={onUpdatePaymentMethodClick}>
                        UPDATE PAYMENT METHOD
                    </button>
                </div>
            )
            break;
        }
    }

    return (
        <div
            style={{
                display: "flex",
                flexFlow: "column",
                alignItems: "center",
                gap: "12pt"
            }}
        >
            <div style={{ display: "flex", gap: "30pt" }}>
                <div
                    style={{
                        display: "flex",
                        width: "120pt",
                        aspectRatio: "0.66",
                        alignItems: "center"
                    }}>
                    <PaidPlan
                        planId={currentSubscription.plan_id}
                        isCurrent={true}
                    />
                </div>
                <Statistics />
            </div>
            {subscriptionOptionsJsx}
        </ div>
    )
}

function Statistics() {
    const backendCredit = useSelector(creditSelector);
    const pendingCost = useSelector(uploadsPendingCostSelector);
    const backendStorage = useSelector(storageSelector);
    const pendingStorageUsage = useSelector(uploadsPendingStorageSelector);

    const totalSpendings = backendCredit.spendings + pendingCost;
    const availableBalance = backendCredit.topups - totalSpendings;
    const secondsAvailable = availableBalance / costPerSecond;

    const secondsSpent = totalSpendings / costPerSecond;

    const totalStorageUsage = backendStorage.usage + pendingStorageUsage;
    const storageUsagePercent = Math.round(100 * totalStorageUsage / backendStorage.quota);

    return (
        <>
            <div style={{ fontSize: "11pt", display: "flex", flexFlow: "column", gap: "8pt", justifyContent: "flex-start" }}>
                <div style={{ alignSelf: "center" }}>CURRENT USAGE</div>
                <table style={{ width: "180pt" }}>
                    <tbody>
                        <tr>
                            <td colSpan={2} style={{ textAlign: "center" }}>TIME</td>
                        </tr>
                        <tr>
                            <td>available:</td>
                            <td style={{ fontFamily: "var(--nfai-mono-font-family)", fontWeight: "bold", textAlign: "right" }}>{secondsToHhMmSs(secondsAvailable)}</td>
                        </tr>
                        <tr>
                            <td>run so far:</td>
                            <td style={{ fontFamily: "var(--nfai-mono-font-family)", textAlign: "right" }}>{secondsToHhMmSs(secondsSpent)}</td>
                        </tr>
                        <tr>
                            <td colSpan={2} style={{ textAlign: "center" }}>STORAGE</td>
                        </tr>
                        <tr>
                            <td>free:</td>
                            <td style={{ fontFamily: "var(--nfai-mono-font-family)", fontWeight: "bold", textAlign: "right" }}>{bytesToString(backendStorage.quota - totalStorageUsage)}</td>
                        </tr>
                        <tr>
                            <td>used:</td>
                            <td style={{ fontFamily: "var(--nfai-mono-font-family)", textAlign: "right" }}>{bytesToString(totalStorageUsage)} ({storageUsagePercent}%)</td>
                        </tr>
                    </tbody>
                </table>
            </div >
        </>
    )
}

function PlanUpgrade() {
    const availablePlanIds = useSelector(availablePlansSelector);

    if (!availablePlanIds)
        return null;

    return (
        <div style={{ marginTop: "12pt", display: "flex", flexFlow: "column", gap: "12pt", alignItems: "center" }}>
            UPGRADE TO PAID PLAN:
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", gap: "32pt" }}>
                {
                    availablePlanIds.map((planId) => {
                        return (
                            <div
                                key={planId}
                                style={{
                                    display: "flex",
                                    width: "120pt",
                                    aspectRatio: "0.66",
                                    // opacity: pendingCheckout !== null && pendingCheckout !== product.id ? 0.25 : 1.0
                                }}
                            >
                                <PaidPlan
                                    planId={planId}
                                    isCurrent={false}
                                />
                            </div>
                        )
                    })
                }
            </div >
        </div>
    )
}

function FreePlan() {
    return (
        <div
            className="button"
            style={{
                position: "relative",
                backgroundColor: "var(--nfai-lightcyan)",
                width: "100%",
                height: "100%",
                borderRadius: "5pt"
            }}
        >
            <div style={{ position: "absolute", width: "100%", height: "24pt", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ fontWeight: "bold" }}>FREE</div>
            </div>
            <img src={checkmark_png} style={{ position: "absolute", top: "24pt", left: "10%", width: "80%", objectFit: "contain", aspectRatio: "1" }} />
            <div style={{ position: "absolute", width: "100%", bottom: "0pt", aspectRatio: "2", display: "flex", flexFlow: "column", justifyContent: "center", alignItems: "center" }}>
                <div>{bytesToString(104857600)} storage</div>
            </div>
        </div>
    )
}

function PaidPlan({ planId, isCurrent }) {
    const plan = useSelector(planSelector(planId));
    const [busy, setBusy] = useState(false);

    if (!plan)
        return null;

    const onClick = () => {
        if (isCurrent || busy)
            return;
        setBusy(true);
        Api.createPlanCheckout(planId)
            .then(({ checkoutUrl }) => {
                window.location.replace(checkoutUrl)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => setBusy(false))
    }

    const costPerSecond = 100;

    return (
        <div
            // className = "button"
            className={isCurrent ? "button" : "button active"}
            style={{
                position: "relative",
                backgroundColor: isCurrent ? "var(--nfai-lightcyan)" : "var(--nfai-yellow)",
                width: "100%",
                height: "100%",
                borderRadius: "5pt"
            }}
            onClick={onClick}
        >
            <div style={{ position: "absolute", width: "100%", height: "24pt", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ fontWeight: "bold" }}>{plan.name.toUpperCase()}</div>
            </div>
            {
                busy ?
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", aspectRatio: "1" }}><MoonLoader /></div> :
                    <img src={isCurrent ? checkmark_png : mic_png} style={{ position: "absolute", top: "24pt", left: "10%", width: "80%", objectFit: "contain", aspectRatio: "1" }} />
            }
            <div style={{ position: "absolute", width: "100%", bottom: "0pt", aspectRatio: "2", display: "flex", flexFlow: "column", justifyContent: "center", alignItems: "center" }}>
                <div>{Math.round(plan.topup_amount / (costPerSecond * 60))} minutes</div>
                <div>{bytesToString(plan.quota)} storage</div>
                <div style={{ fontWeight: "bold", fontSize: "12pt", fontFamily: "var(--nfai-mono-font-family)" }}>${plan.price_cents / 100} / month</div>
            </div>
        </div>
    )
}

function TopupSection({ onWentToStore }) {
    const productIds = useSelector(availableProductsSelector);
    if (!productIds)
        return null;

    return (
        <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
            <p>
                BUY INSTANT PROCESSING TIME:
            </p>
            <div style={{ display: "flex", gap: "32pt", alignContent: "flex-start", alignItems: "center", width: "100%" }}>
                {
                    productIds.map((productId) => {
                        return (
                            <div
                                key={productId}
                                style={{
                                    display: "flex",
                                    width: "100pt",
                                    aspectRatio: "1",
                                }}
                            >
                                <Product
                                    productId={productId}
                                    onWentToStore={onWentToStore}
                                />
                            </div>
                        )
                    })
                }
            </div >
        </div>
    )
}

function Product({ productId, onWentToStore }) {
    const [busy, setBusy] = useState(false);
    const product = useSelector(productSelector(productId));
    if (!product)
        return null;

    const costPerSecond = 100;

    const onClick = () => {
        if (busy)
            return;
        setBusy(true);
        // onWentToStore();
        Api.createProductCheckout(product.id)
            .then((response) => {
                const checkoutUrl = response.checkoutUrl;
                console.log(response);
                window.location.href = checkoutUrl;
            })
            .catch((err) => console.log(err))
            .finally(() => setBusy(false))
    }

    return (
        <div
            className="button active"
            style={{ position: "relative", backgroundColor: "var(--nfai-yellow)", width: "100%", height: "100%", borderRadius: "5pt" }}
            onClick={onClick}
        >
            {
                busy &&
                <div style={{ display: "flex", width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}><MoonLoader /></div>
            }
            <img src={stopwatch_png} style={{ position: "absolute", objectFit: "contain", left: "30%", right: "30%", top: "10%", width: "40%", aspectRatio: "1" }} />
            <div style={{ position: "absolute", width: "100%", bottom: "0pt", aspectRatio: "2", display: "flex", flexFlow: "column", justifyContent: "center", alignItems: "center" }}>
                <div>{product.topup_amount / (60 * costPerSecond)} minutes</div>
                <div style={{ fontWeight: "bold", fontSize: "12pt", fontFamily: "var(--nfai-mono-font-family)" }}>${product.price_cents / 100}</div>
            </div>
        </div>
    )
}

import { ASPECT_RATIO } from "./config";
import Navbar from "./components/navbar/Navbar";
import { Route, Routes } from "react-router-dom";
import User from "./components/pages/User";
import Voices from "./components/pages/Voices";
import Voice from "./components/pages/Voice";
import Conversions from "./components/pages/Conversions";


export default function Main() {
  return (
    <div style={{ position: "absolute", width: "100%", height: "100%" }}>
      <div style={{ position: "absolute", minWidth: "700pt", width: "100%", height: "48pt" }}>
        <Navbar />
      </div>
      <div style={{ display: "flex", justifyContent: "center", position: "absolute", top: "48pt", bottom: "0pt", width: "100%" }}>
        <div style={{ position: "relative", height: "100%", aspectRatio: ASPECT_RATIO }}>
          <Routes>
            <Route path="/" element={<Voices />} />
            <Route path="/voices" element={<Voices />} />
            <Route path="/voices/:voiceId" element={<Voice />} />
            <Route path="/conversions" element={<Conversions />} />
            <Route path="/users/:userId" element={<User />} />
          </Routes>
        </div>
      </div>
    </div >
  )
}

import { useCallback } from 'react';
import { useDropzone } from "react-dropzone";
import no_png from '../../assets/no.png';
import yes_png from '../../assets/yes.png';

export default function FileDropArea({
    onFileDropped,
    noClick = true,
    acceptedFileTypes = { 'audio/wav': ['.wav'] },
}) {
    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles[0]) {
            onFileDropped(acceptedFiles[0]);
        }
    }, [onFileDropped])

    const { getRootProps, isDragActive, isDragAccept } = useDropzone({
        maxFiles: 1,
        accept: acceptedFileTypes,
        noClick: noClick,
        onDrop: onDrop,
    });

    return (
        <div
            {...getRootProps({
                style: { borderRadius: "inherit", position: 'relative', width: "100%", height: "100%" },
            })}
        >
            {
                isDragActive ?
                    (isDragAccept ?
                        (
                            <div style={{ borderRadius: "inherit", position: "relative", width: "100%", height: "100%" }}>
                                <div style={{ borderRadius: "inherit", display: 'flex', flexFlow: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center', backgroundColor: "var(--nfai-yellow)", color: "var(--nfai-black)", width: '100%', height: '100%' }} >
                                    <img src={yes_png} alt="YES" style={{ aspectRatio: 1, maxWidth: '40%', maxHeight: "40%" }} />
                                </div>
                            </div>
                        ) :
                        (
                            <div style={{ borderRadius: "inherit", position: "relative", width: "100%", height: "100%" }}>
                                <div style={{ borderRadius: "inherit", display: 'flex', flexFlow: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center', backgroundColor: "var(--nfai-orange)", color: "var(--nfai-black)", width: '100%', height: '100%' }}>
                                    <img src={no_png} alt="NO" style={{ aspectRatio: 1, maxWidth: '40%', maxHeight: "40%" }} />
                                </div>
                                {/* <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", alignContent: "center", position: "absolute", top: "65%", width: "100%" }}>
                                    <p style={{ color: "var(--nfai-black)", fontSize: '11pt' }}>NOT ACCEPTED</p>
                                </div> */}
                            </div>
                        )
                    ) : null
            }
        </div >
    );
}

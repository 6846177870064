import { useSelector } from "react-redux";
import { uploadsPendingStorageSelector } from "../../redux/uploadsSlice";
import { meSelector, planIdSelector, storageSelector, subscriptionSelector } from "../../redux/globalSlice";
import { useEffect, useRef } from "react";
import { planSelector } from "../../redux/plansSlice";
import { useNavigate } from "react-router-dom";

export default function StorageIndicator() {
    const navigate = useNavigate();
    const user = useSelector(meSelector);
    const pendingStorageUsage = useSelector(uploadsPendingStorageSelector);
    const backendStorage = useSelector(storageSelector)
    const canvasRef = useRef(null);

    const totalStorageUsage = backendStorage.usage + pendingStorageUsage;

    const usage = backendStorage.quota === 0 ? 1 : totalStorageUsage / backendStorage.quota;
    let color;
    if (usage >= 0.95)
        color = "#ee1212";
    else if (usage >= 0.8)
        color = "#fd7a52";
    else
        color = "#4fac43";

    useEffect(() => {
        const canvas = canvasRef.current
        const ctx = canvas.getContext('2d')
        ctx.fillStyle = "#1a1a1a";
        ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height)
        const aspect = 3.0;
        const numSegments = 3;
        const lineWidth = 8;
        // const thickLineWidth = 12;
        const containerHeight = canvas.height * (1 - 1 / aspect);
        const spacing = containerHeight / numSegments;
        const offset = 0.5 * ctx.canvas.height / aspect;
        const radiusX = 0.5 * ctx.canvas.width - lineWidth / 2;
        const radiusY = radiusX / aspect - lineWidth / 2;
        const innerRadiusX = radiusX;// * 0.8
        const innerRadiusY = radiusY;// * 0.8
        const bottom = ctx.canvas.height - offset;
        const top = bottom - usage * containerHeight;

        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = "#c1c1c1";
        ctx.fillStyle = "#1a1a1a";
        for (let segment = 0; segment <= numSegments; segment++) {
            ctx.beginPath();
            ctx.ellipse(ctx.canvas.width / 2, ctx.canvas.height - offset - segment * spacing, radiusX * 1, radiusY * 1, 0, 0, 2 * Math.PI, false);
            ctx.fill();
            ctx.beginPath();
            ctx.ellipse(ctx.canvas.width / 2, ctx.canvas.height - offset - segment * spacing, radiusX, radiusY, 0, 0, Math.PI, false);
            ctx.stroke();
        }

        ctx.fillStyle = color;
        ctx.beginPath();
        ctx.ellipse(ctx.canvas.width / 2, bottom, innerRadiusX, innerRadiusY, 0, 0, 2 * Math.PI, false);
        ctx.fill();
        ctx.fillRect(ctx.canvas.width / 2 - innerRadiusX, top, 2 * innerRadiusX, bottom - top);
        ctx.beginPath();
        ctx.ellipse(ctx.canvas.width / 2, top, innerRadiusX, innerRadiusY, 0, 0, 2 * Math.PI, false);
        ctx.fill();

        // ctx.lineWidth = thickLineWidth;
        ctx.strokeStyle = "#1a1a1a";
        for (let segment = 0; segment < numSegments; segment++) {
            ctx.beginPath();
            ctx.ellipse(ctx.canvas.width / 2, ctx.canvas.height - offset - segment * spacing, radiusX, radiusY, 0, 0, Math.PI, false);
            ctx.stroke();
        }
        ctx.beginPath();
        ctx.ellipse(ctx.canvas.width / 2, ctx.canvas.height - offset - numSegments * spacing, radiusX, radiusY, 0, 0, 2 * Math.PI, false);
        ctx.stroke();

        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = "#c1c1c1";
        for (let segment = 0; segment < numSegments; segment++) {
            ctx.beginPath();
            ctx.ellipse(ctx.canvas.width / 2, ctx.canvas.height - offset - segment * spacing, radiusX, radiusY, 0, 0, Math.PI, false);
            ctx.stroke();
        }
        ctx.beginPath();
        ctx.ellipse(ctx.canvas.width / 2, ctx.canvas.height - offset - numSegments * spacing, radiusX, radiusY, 0, 0, 2 * Math.PI, false);
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(ctx.canvas.width / 2 - radiusX, bottom - containerHeight);
        ctx.lineTo(ctx.canvas.width / 2 - radiusX, bottom);
        ctx.stroke();
        ctx.beginPath();
        ctx.moveTo(ctx.canvas.width / 2 + radiusX, bottom - containerHeight);
        ctx.lineTo(ctx.canvas.width / 2 + radiusX, bottom);
        ctx.stroke();
    }, [usage, color])

    const onClick = (e) => {
        e.stopPropagation();
        navigate("/users/" + user.id);
    }

    return (
        <div
            className="active"
            onClick={onClick}
            style={{ display: "flex", alignContent: "flex-start", gap: "6pt", alignItems: "center" }}
        >
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", position: "relative", width: "16pt", height: "16pt" }}>
                <canvas ref={canvasRef} width="100" height="100" style={{ position: "absolute", height: "100%", width: "100%" }} />
            </div>
            <p className="active" style={{ color: color }}>{Math.round(usage * 100) / 1}%</p>
        </div>
    )
}

export function StorageIndicatorIcon() {

}
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../api/api";
import { addCollectionReducers } from "./collection";
import { addEntityFetchReducers } from "./entity";
import { logOut } from "./globalSlice";


export const modelsSlice = createSlice({
    name: 'models',
    initialState: {
        entities: {},               // individual models
        collections: {              // arrays of modelIDs keyed by voiceID
            byVoiceId: {}
        }
    },
    extraReducers(builder) {
        addEntityFetchReducers(builder, modelThunk)
        addCollectionReducers(builder, modelsByVoiceIdThunk, modelsByVoiceIdSelector, modelsByVoiceIdCreator)
        builder.addCase(logOut, (state, action) => {
            state.entities = {};
            state.collections = {
                byVoiceId: {}
            }
        })
    }
})

export const modelThunk = createAsyncThunk('models/fetchModel', async (modelId, { getState, requestId }) => {
    const state = getState();
    const { currentRequestId } = state.models.entities[modelId];
    if (currentRequestId !== requestId)
        return;
    return await Api.getModel(modelId);
})

export const modelEntity = (modelId) => {
    return {
        selector: (state) => state.models.entities[modelId],
        action: modelThunk(modelId)
    }
}

/**
 * models.collections.byVoiceId
 */
const modelsByVoiceIdThunk = createAsyncThunk('models/fetchModelsByVoiceId', async (voiceId, { getState, requestId }) => {
    const state = getState();
    const { currentRequestId } = state.models.collections.byVoiceId[voiceId];
    if (currentRequestId !== requestId)
        return;
    return await Api.getModels({ voiceId });
})
const modelsByVoiceIdSelector = (voiceId) => {
    return (state) => state.collections.byVoiceId[voiceId];
}
const modelsByVoiceIdCreator = (voiceId, state, initialState) => {
    state.collections.byVoiceId[voiceId] = initialState;
}
export const modelsByVoiceId = (voiceId) => {
    return {
        selector: (state) => state.models.collections.byVoiceId[voiceId],
        action: modelsByVoiceIdThunk(voiceId)
    }
}


export default modelsSlice.reducer

import { Link, useNavigate } from "react-router-dom";
import nfai_logo from '../../assets/nfai_logo.png';
import nfai_name from '../../assets/nfai_name.png';
import SignOutButton from "./SignOutButton";
import { useDispatch, useSelector } from "react-redux";
import { UserHotText } from "../widgets/HotText";
import UploadIndicator from "./UploadIndicator";
import { logOut, meSelector } from "../../redux/globalSlice";
import BalanceIndicator from "./BalanceIndicator";
import StorageIndicator from "./StorageIndicator";
import { useState } from "react";
import UploadsModal from "./UploadsModal";
import { useAuthenticator } from "@aws-amplify/ui-react";


export default function Navbar() {
    const dispatch = useDispatch();
    const { signOut } = useAuthenticator((context) => [context.user]);
    const currentUser = useSelector(meSelector);

    const navigate = useNavigate();
    const [uploadsOpen, setUploadsOpen] = useState(false);

    const onUploadsClicked = () => {
        setUploadsOpen(true);
    }

    const onSignOut = () => {
        dispatch(logOut());
        signOut();
    }

    return (
        <div className="navbar" style={{ padding: '0pt 12pt 0pt 6pt', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: "100%", width: "100%" }}>
            <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '5pt', alignItems: 'center', aspectRatio: "5", height: "100%" }}>
                <Link to="/conversions" style={{ height: "100%" }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '5pt', alignItems: 'center', aspectRatio: "4", height: "100%" }}>
                        <img src={nfai_logo} style={{ height: "70%" }} alt="" />
                        <img src={nfai_name} style={{ height: "20%" }} alt="" />
                    </div>
                </Link>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: "center", gap: '24pt' }}>
                    <p className="active" onClick={() => navigate("/voices")}><span className="active">VOICES</span></p>
                    <div style={{ display: "flex", alignItems: "center", gap: "8pt" }}>
                        <p className="active" onClick={() => navigate("/conversions")}>CONVERSIONS</p>
                        <UploadIndicator onClick={onUploadsClicked} />
                    </div>
                </div>
            </div>
            <div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10pt', alignItems: 'center' }}>
                    <p>
                        <UserHotText userId={currentUser.id} />
                    </p>
                    <BalanceIndicator />
                    <StorageIndicator />
                    <SignOutButton
                        onClick={onSignOut}
                    />
                </div>
                <UploadsModal isOpen={uploadsOpen} />
            </div>
        </div>
    )
}

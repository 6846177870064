import VoiceCard from "../cards/VoiceCard";
import { voicesCollection } from "../../redux/voicesSlice";
import { useInfiniteCollection } from "../../redux/infiniteCollection";
import { useCallback, useEffect, useRef } from "react";
import { MoonLoader } from "react-spinners";

export default function Voices() {
    const { ids, haveMore, fetchMore, currentRequestId } = useInfiniteCollection(voicesCollection(false, 3)) || {}

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexFlow: "column", height: "100%" }}>
            <div
                style={{
                    height: '300pt', overflow: 'auto', width: "80%", gap: "20pt",
                    display: "flex", justifyContent: "center", flexWrap: "wrap"
                }}
            >
                {
                    ids.map((voiceId) => <VoiceCard key={voiceId} voiceId={voiceId} />)
                }
                {
                    haveMore && <div style={{ width: '200pt', height: '280pt', padding: "10pt" }}><div style={{ width: "100%", height: "100%", borderRadius: "3pt", backgroundColor: "var(--nfai-darkcyan)"}}><LoadMore size="40" currentRequestId={currentRequestId} fetchMore={fetchMore}/></div></div>
                }
            </div>
        </div>
    );
}

function LoadMore({ currentRequestId, fetchMore }) {
    const ref = useRef(null);
    
    const intersectionCallback = useCallback((e) => {
        if (!currentRequestId && e[0].isIntersecting) {
            fetchMore()
        }
    }, [currentRequestId, fetchMore])

    useEffect(() => {
        const observer = new IntersectionObserver(intersectionCallback, {
            root: null,
            rootMargin: "0px",
            threshold: "0.0"
        })
    
        if (ref.current)
            observer.observe(ref.current);

        return () => {
            if (ref.current)
                observer.unobserve(ref.current);
        }
    }, [intersectionCallback])

    return (
        <div ref={ref} style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <MoonLoader />
        </div>
    )
}


/*
export default function Voices() {
    const collection = usePaginatedCollection(voicesCollection(false, 3, 2)) || {}

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexFlow: "column", height: "100%" }}>
            <div
                style={{
                    height: '300pt', overflow: 'auto', width: "80%", gap: "20pt",
                    display: "flex", justifyContent: "center",  flexWrap: "nowrap"
                }}
            >
                {
                    collection.currentPage?.ids.map((voiceId) => <VoiceCard key={voiceId} voiceId={voiceId} />)
                }
            </div>
            <PageSelector paginatedCollection={collection} />
        </div>
    );
}
*/
import './App.css';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import { useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import Api from './api/api';
import Main from './Main';
import { ScaleLoader } from 'react-spinners';
import { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { logIn, logOut, meSelector } from './redux/globalSlice';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { setPlans } from './redux/plansSlice';
import { setProducts } from './redux/productsSlice';


Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
  }
})


export let currentAudio = undefined;

export function pauseAudio() {
  currentAudio?.pause();
}

export function playAudio(audio) {
  pauseAudio();
  currentAudio = audio;
  currentAudio?.play();
}

export const player = new Audio();
export const cdnUrl = process.env.REACT_APP_CDN_URL;

export default function App() {
  const dispatch = useDispatch();
  const currentUser = useSelector(meSelector);

  const { signOut } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    Promise.all([Api.getMe(), Api.getPlans(), Api.getProducts()])
      .then(values => {
        const currentUser = values[0];
        const plans = values[1];
        const products = values[2];
        dispatch(logIn(currentUser));
        dispatch(setPlans(plans))
        dispatch(setProducts(products))
      })
      .catch((err) => {
        console.error("Could not get account/plan data: " + err.message);
        dispatch(logOut());
        signOut();
      })
  }, [dispatch]);

if (!currentUser) {
  return (
    <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", position: "relative", width: '100vw', height: '100vh' }}>
      <ScaleLoader color="var(--nfai-black)" width="50pt" height="50pt" loading="true" style={{ loading: true, opacity: "0.5" }} />
    </div>
  );
} else {
  return (
    <div>
      <div id="main" style={{ minWidth: '600pt' }}>
        <Main />
      </div>
      <Toaster
        className="toaster"
        toastOptions={{
          className: '',
          style: {
            border: '1px solid var(--nfai-black)',
            padding: '16px',
            backgroundColor: 'var(--nfai-yellow)',
          },
        }}
        containerStyle={{
          top: "60pt",
          left: 20,
          bottom: 20,
          right: "10pt"
        }}
        position="top-right" />
    </div>
  );
}
}

// normalized, as advised in https://redux.js.org/usage/structuring-reducers/normalizing-state-shape

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../api/api";
import { addEntityFetchReducers, addEntityUpdateReducers } from "./entity";
// import { addPaginatedCollectionReducers } from "./paginatedCollection";
import { addInfiniteCollectionReducers } from "./infiniteCollection";
import { logOut } from "./globalSlice";


export const voicesSlice = createSlice({
    name: 'voices',
    initialState: {
        entities: {},                   // individual voices
        collection: {                   // IDs only
            // pages: [],
            ids: [],
            haveMore: true,
        }
    },
    reducers: {},
    extraReducers(builder) {
        addEntityFetchReducers(builder, voiceFetchThunk)
        addEntityUpdateReducers(builder, voiceUpdateThunk)
        // addPaginatedCollectionReducers(
        //     builder,
        //     voicesCollectionThunk,
        //     voicesCollectionSelector,
        //     voicesCollectionReducerIntercept,
        //     voicesCollectionRefreshAction
        // )
        addInfiniteCollectionReducers(
            builder,
            voicesCollectionThunk,
            voicesCollectionSelector,
            voicesCollectionReducerIntercept,
            voicesCollectionRefreshAction
        )
        builder.addCase(logOut, (state, action) => {
            state.entities = {};
            state.collection = {
                // pages: [],
                ids: [],
                haveMore: true,
            };
        })
    },
})

/**
 * GET /voices/:voiceId => voices.entities.voiceId.data
 */
const voiceFetchThunk = createAsyncThunk('voices/fetchVoice', async (voiceId, { getState, requestId }) => {
    const state = getState();   // avoid sending request if it's going to be discarded anyway
    const { currentRequestId } = state.voices.entities[voiceId];
    if (currentRequestId !== requestId)
        return;
    return await Api.getVoice(voiceId);
})

export const voiceEntity = (voiceId) => {
    return {
        selector: (state) => state.voices.entities[voiceId],
        action: voiceFetchThunk(voiceId)
    }
}

/**
 * PATCH /voices/:voiceId => voices.entities.voiceId.data
 */
const voiceUpdateThunk = createAsyncThunk('voices/updateVoice', async ({ id, updates }, { getState, requestId }) => {
    const state = getState();
    const { currentRequestId } = state.voices.entities[id];
    if (currentRequestId !== requestId)
        return;
    return await Api.patchVoice(id, updates);
})

export const likeVoice = (voiceId) => voiceUpdateThunk({ id: voiceId, updates: { liked_by_me: 1 } });
export const unlikeVoice = (voiceId) => voiceUpdateThunk({ id: voiceId, updates: { liked_by_me: 0 } });
export const updateVoice = (voiceId, updates) => voiceUpdateThunk({ id: voiceId, updates: updates });

/**
 * GET /voices
 */
/*
const voicesCollectionThunk = createAsyncThunk('voices/fetchVoices', async ({ link, limit, desc }, { getState, requestId }) => {
    console.log(`THUNK: link: ${link}, limit: ${limit}, desc: ${desc}`)
    const state = getState();
    const { currentRequestId } = state.voices.collection;
    if (currentRequestId !== requestId)
        return;
    return await Api.getVoices({ link, limit, desc });
})
const voicesCollectionSelector = (state) => state.collection;
const voicesCollectionRefreshAction = { type: "voices/refresh", payload: null }
const voicesCollectionReducerIntercept = (actionCreator, state, action) => {
    if (actionCreator === voicesCollectionThunk.fulfilled) {
        const voices = action.payload.data;
        voices.forEach((voice) => {
            const entity = state.entities[voice.id];
            if (entity?.currentRequestId !== undefined)
                return;
            state.entities[voice.id] = {
                data: voice,
                lastFetched: Date.now()
            }
        })
    }
}
export const voicesCollection = (desc, pageSize, numPrefetchedPages) => {
    return {
        selector: (state) => state.voices.collection,
        action: voicesCollectionThunk,
        desc,
        pageSize,
        numPrefetchedPages,
        refreshAction: voicesCollectionRefreshAction
    }
}
*/

const voicesCollectionThunk = createAsyncThunk('voices/fetchVoices', async ({ limit, desc }, { getState, requestId }) => {
    const state = getState();
    const { currentRequestId } = state.voices.collection;
    if (currentRequestId !== requestId)
        return;
    const link = state.voices.collection.ids[state.voices.collection.ids.length - 1];
    return await Api.getVoices({ link, limit, desc });
})
const voicesCollectionSelector = (state) => state.collection;
const voicesCollectionRefreshAction = { type: "voices/refresh", payload: null }
const voicesCollectionReducerIntercept = (actionCreator, state, action) => {
    if (actionCreator === voicesCollectionThunk.fulfilled) {
        const voices = action.payload.data;
        voices.forEach((voice) => {
            const entity = state.entities[voice.id];
            if (entity?.currentRequestId !== undefined)
                return;
            state.entities[voice.id] = {
                data: voice,
                lastFetched: Date.now()
            }
        })
    }
}
export const voicesCollection = (desc, numPrefetchedItems) => {
    return {
        selector: (state) => state.voices.collection,
        desc,
        numPrefetchedItems,
        fetchMoreThunk: voicesCollectionThunk,
        refreshAction: voicesCollectionRefreshAction
    }
}

export default voicesSlice.reducer
import { createSlice } from "@reduxjs/toolkit";


export const productsSlice = createSlice({
    name: 'products',
    initialState: {
        entities: {},
        collections: {
            available: []
        }
    },
    reducers: {
        setProducts: (state, action) => {
            const products = action.payload;
            products.forEach((product) => {
                state.entities[product.id] = product
            })
            state.collections.available = products.map((product) => product.id)
        }
    }
})

export const productSelector = (productId) => {
    return (state) => state.products.entities[productId];
}

export const availableProductsSelector = (state) => state.products.collections.available;

export const { setProducts } = productsSlice.actions
export default productsSlice.reducer
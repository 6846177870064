import { useRef } from "react";
import { useInfiniteCollection } from "../../redux/infiniteCollection";
import { usePaginatedCollection } from "../../redux/paginatedCollection";
import { tasksCollection } from "../../redux/tasksSlice";
import TaskCard from "../cards/TaskCard";
import LoadingCard from "../widgets/LoadingCard";
import PageSelector from "../widgets/PageSelector";

export default function Conversions() {
    const { ids, haveMore, fetchMore, currentRequestId } = useInfiniteCollection(tasksCollection(true, 1)) || {}
    const ref = useRef(null);

    return (
        <div
            style={{
                ref: { ref },
                position: "absolute", top: "20pt", bottom: "20pt", width: "100%", overflow: "scroll",
                display: "flex", margin: "auto", alignItems: "stretch", flexDirection: "column", gap: "12pt"
            }}>
            {
                ids?.map((taskId) => <div key={taskId} style={{ width: '100%', height: '60pt' }}><TaskCard taskId={taskId} parentRef={ref} /></div>)
            }
            {
                haveMore && <div style={{ width: '100%', height: '60pt' }}><div style={{ width: "100%", height: "100%", borderRadius: "3pt", backgroundColor: "var(--nfai-darkcyan)" }}><LoadingCard size="40" currentRequestId={currentRequestId} fetchMore={fetchMore} /></div></div>
            }
        </div>
    );
}

/*
export default function Conversions() {
    const collection = usePaginatedCollection(tasksCollection(true, 4, 2)) || {}

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", flexFlow: "column", gap: "12pt" }}>
            <div
                style={{
                    position: "relative", width: "100%", height: "300pt",
                    display: "flex", flexFlow: "column", justifyContent: "flex-start", alignItems: "stretch", gap: "20pt"
                }}
            >
                {
                    collection.currentPage?.ids.map((taskId) => <TaskCard key={taskId} taskId={taskId} />)
                }
            </div>
            <PageSelector paginatedCollection={collection} />
        </div>
    );
}
*/
import VoiceLikes from "../widgets/VoiceLikes";
import { useNavigate } from "react-router-dom";
import { cdnUrl } from "../../App";
import { useEntity } from '../../redux/entity';
import { voiceEntity } from '../../redux/voicesSlice';
import { UserHotText } from '../widgets/HotText';
import mic_png from '../../assets/mic.png'

export default function VoiceCard({ voiceId }) {
    const navigate = useNavigate();
    const { data: voice } = useEntity(voiceEntity(voiceId)) || {}

    const onCardClicked = () => {
        navigate(`/voices/${voiceId}`);
    }

    if (!voice)
        return null;

    const imageSrc = voice.have_image ? `${cdnUrl}/public/${voice.owner_id}/voices/${voice.id}/image.png` : mic_png;

    return (
        <div style={{ position: 'relative', width: '200pt', height: '280pt', padding: '10pt', borderRadius: "3pt" }}>
            <div style={{ position: 'absolute', borderRadius: "inherit", cursor: "pointer" }} onClick={onCardClicked}>
                <div style={{ width: "180pt", height: "260pt", backgroundColor: "var(--nfai-black)", color: "var(--nfai-washed-white)", borderRadius: "inherit" }}>
                    <div
                        style={{ position: 'absolute', top: '10pt', left: '0pt', width: '100%', borderRadius: "inherit" }}
                    >
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', height: '10pt', width: '100%' }}>
                            {voice.name}
                        </div>
                        <div style={{ position: 'absolute', top: '0pt', width: '100%', aspectRatio: 1, borderRadius: "inherit" }}>
                            <div style={{ position: 'absolute', left: '10pt', top: '40pt', right: '10pt', aspectRatio: 1, overflow: 'hidden', borderRadius: "inherit" }}>
                                <img src={imageSrc} alt="IMG" style={{ objectFit: "contain", width: "100%", aspectRatio: "1" }} />
                            </div>
                        </div>
                        <div style={{ backgroundColor: "transparent", fontSize: '11pt', display: 'flex', gap: '4pt', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: '18pt', height: '10pt', width: '100%' }}>
                            <p>by <UserHotText userId={voice.owner_id} /></p>
                        </div>
                    </div>
                </div >
            </div>
            <div style={{ position: 'absolute', display: 'flex', top: '0pt', right: '0pt', width: '30pt', height: '30pt' }}>
                <VoiceLikes voiceId={voice.id} />
            </div>
        </div >
    );
}


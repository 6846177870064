import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export function addCollectionReducers(builder, thunk, selector, creator) {
    builder.addCase(thunk.pending, (state, action) => {
        const arg = action.meta.arg;
        let collection = (selector(arg))(state);
        if (collection) {
            if (collection.currentRequestId)
                return;
            collection.currentRequestId = action.meta.requestId;
        } else {
            creator(arg, state, {
                ids: [],
                lastFetched: undefined,
                currentRequestId: action.meta.requestId,
                error: undefined,
            })
        }
    })
    builder.addCase(thunk.fulfilled, (state, action) => {
        const arg = action.meta.arg;
        const collection = (selector(arg))(state);
        if (collection.currentRequestId !== action.meta.requestId)
            return;
        collection.currentRequestId = undefined;
        collection.lastFetched = Date.now();
        const collectionEntities = action.payload;
        collection.ids = collectionEntities.map(({ id }) => id);
        // for (const entity of collectionEntities) {
        //     const existingEntity = state.entities[entity.id];
        //     if (existingEntity) {
        //         existingEntity.data = entity;
        //     } else {
        //         state.entities[entity.id] = {
        //             data: entity,
        //             lastFetched: Date.now(),
        //         }
        //     }
        // }
    })
    builder.addCase(thunk.rejected, (state, action) => {
        const arg = action.meta.arg;
        const collection = (selector(arg))(state);
        if (collection.currentRequestId !== action.meta.requestId)
            return;
        collection.currentRequestId = undefined;
        collection.error = action.error;
    })
}

export function useCollection({ selector, action }, dependencies = undefined, cacheExpiry = 60 * 1000) {
    const dispatch = useDispatch();
    const collection = useSelector(selector);
    const lastFetched = collection?.lastFetched;
    const [needRefresh, setNeedRefresh] = useState(!lastFetched || Date.now() - lastFetched > cacheExpiry);
    useEffect(() => {
        if (needRefresh) {
            dispatch(action)
            setNeedRefresh(false);
        }
    }, [needRefresh, selector, action, dispatch]);
    return collection;
}

